import { VFC } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { ClipboardButton as messages } from '../messages';

export const ClipboardButton: VFC<{ value: string } & ButtonProps> = ({ value, children, ...buttonProps }) => {
  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(value).catch(reason => console.error(reason));
  };
  const intl = useIntl();

  return (
    <Tooltip title={intl.formatMessage(messages.tooltip)}>
      <Button icon={<CopyOutlined />} onClick={handleCopyButtonClick} {...buttonProps}>
        {children}
      </Button>
    </Tooltip>
  );
};
