/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment */
/* eslint-disable camelcase, @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-member-access  */
import React, { VFC } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { InventoryLocation } from '../../../types/inventory/inventoryLocation';

export interface LocationCardProps extends Pick<InventoryLocation, 'name' | 'avatar_url'> {
  loading: boolean;
}

const StyledCard = styled(Card)`
  .ant-card-body {
    position: absolute;
    bottom: 0;

    h1 {
      color: #fff;
      margin-bottom: 0;
      opacity: 0.9;
      font-size: 3rem;
      line-height: 3rem;
      text-shadow: -4px 2px 4px rgba(0, 0, 0, 0.8);
    }
  }
`;

type AvatarUrlContainer = Pick<AvatarCardProps, 'avatar_url'>;

const StyledCardCover = styled.div`
  height: 280px;
  background-image: url(${({ avatar_url }: AvatarUrlContainer) => avatar_url ?? 'https://i.stack.imgur.com/y9DpT.jpg'});
  background-size: cover;
  background-position: 50% 50%;
`;

export interface AvatarCardProps extends Pick<InventoryLocation, 'name' | 'avatar_url'> {
  loading: boolean;
}

export const AvatarCard: VFC<AvatarCardProps> = props => {
  const { name, avatar_url, loading } = props;

  return (
    <StyledCard cover={<StyledCardCover avatar_url={avatar_url} />} loading={loading}>
      <h1>{name}</h1>
    </StyledCard>
  );
};
