export enum InventoryMeterMountType {
  Free = 'free',
  ImpulseModule = 'impulseModule',
  MbusModule = 'mbusModule',
  VolumeConverter = 'volumeConverter',
  GalvanicSeparator = 'galvanicSeparator',
  RemoteReading = 'remoteReading',
}

export const inventoryMeterMountTypeTranslated = {
  [InventoryMeterMountType.Free]: { id: 'app.enums.inventoryMeterMountTypeTranslated.Free' },
  [InventoryMeterMountType.ImpulseModule]: { id: 'app.enums.inventoryMeterMountTypeTranslated.ImpulseModule' },
  [InventoryMeterMountType.MbusModule]: { id: 'app.enums.inventoryMeterMountTypeTranslated.MbusModule' },
  [InventoryMeterMountType.VolumeConverter]: { id: 'app.enums.inventoryMeterMountTypeTranslated.VolumeConverter' },
  [InventoryMeterMountType.GalvanicSeparator]: { id: 'app.enums.inventoryMeterMountTypeTranslated.GalvanicSeparator' },
  [InventoryMeterMountType.RemoteReading]: { id: 'app.enums.inventoryMeterMountTypeTranslated.RemoteReading' },
  mountedOccupied: { id: 'app.enums.inventoryMeterMountTypeTranslated.mountedOccupied' },
};
