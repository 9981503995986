/* eslint-disable import/no-extraneous-dependencies */
import gql from 'graphql-tag';
import type { InventoryLocation } from '../../../types/inventory/inventoryLocation';

export const AUTHENTICATE_INVENTORY_LOCATION = gql`
  query authentication {
    inventoryLocations {
      data {
        id
        attributes {
          name
          api_key
        }
      }
    }
  }
`;

export interface InventoryLocationAuthenticationData {
  inventoryLocations: { data?: Pick<InventoryLocation, 'id' | 'name' | 'api_key'>[] };
}
