/* eslint-disable camelcase */
import { IntlShape } from 'react-intl';
import { InventoryMeter } from '@enmon/commons/lib/tools';
import { downloadCSV } from '../../../../utils/csv';
import { inventoryMeterTypeDeviceDescriptor } from '../../../../enums/inventoryMeterType';
import { inventoryMeterAccessibilityDescriptor } from '../../../../enums/inventoryMeterAccessibility';
import { SectionMain, SectionDetails } from '../../routes/inventory_meter_detail/messages';
import { Csv } from '../../messages';
import { MeterFieldsData } from '../../queries/getLocationMetersData';

interface LocationMeterForExport {
  id: string | number;
  meter_type: string;
  monitored_entity: InventoryMeter['monitored_entity'];
  accessibility: string;
  owner: InventoryMeter['owner'];
  tenant: InventoryMeter['tenant'];
  building: InventoryMeter['inventory_location_building']['name'];
  building_floor: InventoryMeter['building_floor'] | string;
  submeter: string;
  new_meter: string;
  meterMountType: InventoryMeter['meter_mount_type'];
  manufacturer: InventoryMeter['meter_model']['manufacturer']['name'];
  meter_model: InventoryMeter['meter_model']['name'];
  serial_number: InventoryMeter['serial_number'];
  manufactured: InventoryMeter['manufactured'];
  calibrated: InventoryMeter['calibrated'];
  lat: InventoryMeter['lat'];
  lng: InventoryMeter['lng'];
  api_key: InventoryMeter['inventory_meter_api_key']['api_key'];
}

export function exportMetersToCsv(locationName: string, inventoryMeters: MeterFieldsData[], intl: IntlShape): void {
  const headers = [
    { name: intl.formatMessage(Csv.meterId), path: 'id' },
    { name: intl.formatMessage(SectionMain.meterType), path: 'meter_type' },
    { name: intl.formatMessage(SectionMain.monitoredEntity), path: 'monitored_entity' },
    { name: intl.formatMessage(SectionMain.accessibility), path: 'accessibility' },
    { name: intl.formatMessage(SectionMain.owner), path: 'owner' },
    { name: intl.formatMessage(SectionMain.tenant), path: 'tenant' },
    { name: intl.formatMessage(SectionMain.building), path: 'building' },
    { name: intl.formatMessage(SectionMain.buildingFloor), path: 'building_floor' },
    { name: intl.formatMessage(SectionMain.isSubmeter), path: 'submeter' },
    { name: intl.formatMessage(SectionMain.isSuggestedNewMeter), path: 'new_meter' },
    { name: intl.formatMessage(SectionMain.meterMountType), path: 'meter_mount_type' },
    { name: intl.formatMessage(SectionDetails.manufacturer), path: 'manufacturer' },
    { name: intl.formatMessage(SectionDetails.meterModel), path: 'meter_model' },
    { name: intl.formatMessage(SectionDetails.serialNumber), path: 'serial_number' },
    { name: intl.formatMessage(SectionDetails.manufactured), path: 'manufactured' },
    { name: intl.formatMessage(SectionDetails.calibrated), path: 'calibrated' },
    { name: intl.formatMessage(Csv.lat), path: 'lat' },
    { name: intl.formatMessage(Csv.lng), path: 'lng' },
    { name: intl.formatMessage(Csv.apiKey), path: 'api_key' },
  ];

  downloadCSV<LocationMeterForExport>(
    intl.formatMessage(Csv.name, { name: locationName }),
    headers,
    inventoryMeters.map(
      (meter: MeterFieldsData): LocationMeterForExport => ({
        id: meter.id,
        meter_type: intl.formatMessage(inventoryMeterTypeDeviceDescriptor[meter.meter_type]),
        monitored_entity: meter.monitored_entity,
        accessibility: meter.accessibility
          ?.split(';')
          ?.map(item =>
            inventoryMeterAccessibilityDescriptor[item]
              ? intl.formatMessage(inventoryMeterAccessibilityDescriptor[item])
              : item,
          )
          .join(','),
        owner: meter.owner,
        tenant: meter.tenant,
        building: meter.inventory_location_building?.name,
        building_floor: meter.building_floor ? meter.building_floor : intl.formatMessage(SectionMain.groundFloor),
        submeter: meter.submeter ? intl.formatMessage(Csv.yes) : intl.formatMessage(Csv.no),
        new_meter: meter.new_meter ? intl.formatMessage(Csv.yes) : intl.formatMessage(Csv.no),
        meterMountType: meter.meter_mount_type,
        manufacturer: meter.meter_model?.manufacturer?.name,
        meter_model: meter.meter_model?.name,
        serial_number: meter.serial_number,
        manufactured: meter.manufactured,
        calibrated: meter.calibrated,
        lat: meter.lat,
        lng: meter.lng,
        api_key: meter.inventory_meter_api_key?.api_key,
      }),
    ),
  );
}
