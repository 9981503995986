import { HttpClient, RequestConfig } from '../httpClient';

export class BaseApiClient<T> {
  protected readonly http: HttpClient;

  constructor(urlPrefix: string) {
    this.http = new HttpClient(urlPrefix);
  }

  // NOTE: if you need params then implement custom find() method in descendant
  public async getAll(options: Omit<RequestConfig, 'params'> = {}): Promise<T[]> {
    return this.http.get<T[]>('', {
      ...options,
      params: {
        // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#limit
        _limit: -1,
      },
    });
  }

  public async findOne(id: number | string, options: RequestConfig = {}): Promise<T> {
    return this.http.get<T>(`/${encodeURIComponent(id)}`, options);
  }

  public async create(body: unknown, options: RequestConfig = {}): Promise<T> {
    return this.http.post<T>('', body, options);
  }

  public async update(id: number | string, body: unknown, options: RequestConfig = {}): Promise<T> {
    return this.http.put<T>(`/${encodeURIComponent(id)}`, body, options);
  }

  public async delete(id: number | string, options: RequestConfig = {}): Promise<T> {
    return await this.http.delete<T>(`/${encodeURIComponent(id)}`, options);
  }
}
