/* eslint-disable no-undef */
import { useRef, useState, useEffect, FC, Children, isValidElement, cloneElement } from 'react';
import { Drawer } from 'antd';
import { useRecoilState } from 'recoil';
import { DrawerContent } from './DrawerContent';
import { selectedMeterAtom } from '../../../auth/atoms';

interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string | number };
}

export const Map: FC<MapProps> = ({ children, style, center, fullscreenControl, streetViewControl, zoom }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const [selectedMeter, setSelectedMeter] = useRecoilState(selectedMeterAtom);

  const drawerHeader = selectedMeter?.inventory_location_building?.name
    ? ` - ${selectedMeter.inventory_location_building.name}`
    : '';

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current));
    }
  }, [map, ref]);

  useEffect(() => {
    if (!map) return;
    map.setOptions({
      ...(center?.lat && center?.lng ? { center } : {}),
      fullscreenControl,
      streetViewControl,
      zoom,
    });
  }, [map, fullscreenControl, streetViewControl, zoom, center]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, child => (isValidElement(child) && map ? cloneElement(child, { map }) : null))}
      <Drawer
        getContainer={false}
        placement="right"
        title={`Meter detail${drawerHeader}`}
        visible={!!selectedMeter}
        onClose={() => setSelectedMeter(undefined)}
      >
        <DrawerContent />
      </Drawer>
    </>
  );
};
