import styled from 'styled-components';
import { Space } from 'antd';

export const FullWidthHeightSpace = styled(Space)`
  height: 100%;
  width: 100%;
  justify-content: center;

  .ant-space-item {
    width: 100%;
    max-width: 400px;
  }
`;
