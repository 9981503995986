/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Card, Descriptions, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InventoryMeterWaterDetails } from '@enmon/commons/lib/tools/inventory_meter';
import { isNil } from 'ramda';
import { DESCRIPTION_TABLE_CONFIG } from '../../../../const';
import { WaterCard as messages } from '../../messages';
import { inventoryMeterOrientationDescriptor } from '../../../../../../enums/inventoryMeterOrientation';

interface WaterCardProps {
  isLoading: boolean;
  typeDetailsData?: InventoryMeterWaterDetails;
}

export const WaterCard: VFC<WaterCardProps> = ({ isLoading, typeDetailsData }) => {
  const { hot_water, flange, diameter_nominal, flowrate, lay_length, orientation } = typeDetailsData ?? {};

  return (
    <Card loading={isLoading} title={<FormattedMessage {...messages.title} />}>
      <Space direction="vertical" size={36} style={{ width: '100%' }}>
        <Descriptions bordered column={DESCRIPTION_TABLE_CONFIG} contentStyle={{ minWidth: 100 }} layout="horizontal">
          {!isNil(hot_water) && (
            <Descriptions.Item label={<FormattedMessage {...messages.hot_water} />}>
              {hot_water ? <FormattedMessage {...messages.yes} /> : <FormattedMessage {...messages.no} />}
            </Descriptions.Item>
          )}
          {!isNil(flange) && (
            <Descriptions.Item label={<FormattedMessage {...messages.flange} />}>
              {flange ? <FormattedMessage {...messages.yes} /> : <FormattedMessage {...messages.no} />}
            </Descriptions.Item>
          )}
          {diameter_nominal && (
            <Descriptions.Item label={<FormattedMessage {...messages.diameterNominal} />}>
              {diameter_nominal}
            </Descriptions.Item>
          )}
          {flowrate && (
            <Descriptions.Item label={<FormattedMessage {...messages.flowrate} />}>
              {flowrate} {`m${String.fromCharCode(179)}/h`}
            </Descriptions.Item>
          )}
          {lay_length && (
            <Descriptions.Item label={<FormattedMessage {...messages.layLength} />}>{lay_length} mm</Descriptions.Item>
          )}
          {orientation && (
            <Descriptions.Item label={<FormattedMessage {...messages.orientation} />}>
              <FormattedMessage {...inventoryMeterOrientationDescriptor[orientation]} />
            </Descriptions.Item>
          )}
        </Descriptions>
      </Space>
    </Card>
  );
};
