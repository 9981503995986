import { useEffect, VFC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Input, Form, Button, notification } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useLazyQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { deserialise } from '@enmon/commons';
import { FullWidthHeightSpace } from './components/FullWidthHeightSpace';
import { AUTHENTICATE_INVENTORY_LOCATION, InventoryLocationAuthenticationData } from './queries/getLocationDetailData';
import { Layout } from '../../components/Layout/Layout';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { AuthPage as messages } from './messages';
import { apiKeyState, locationIdState } from './atoms';
import { AuthContext } from '../../services/apolloClient';

const FormItem = Form.Item;

export function useUrlQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export const AuthPage: VFC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const queryApiKey = useUrlQuery().get('apiKey') ?? null;

  const [apiKey, setApiKey] = useRecoilState(apiKeyState);
  const setLocationId = useSetRecoilState(locationIdState);

  // Query list data
  const [authenticate, { loading }] = useLazyQuery<InventoryLocationAuthenticationData, Record<string, never>>(
    AUTHENTICATE_INVENTORY_LOCATION,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      onCompleted: response => {
        const { inventoryLocations } = deserialise<InventoryLocationAuthenticationData>(response) ?? {};
        const [inventoryLocation] = inventoryLocations?.data ?? [];

        if (inventoryLocation) {
          setApiKey(inventoryLocation.api_key);
          setLocationId(inventoryLocation.id);
          notification.success({
            message: intl.formatMessage(messages.loginSuccess),
            top: 96,
            duration: 2,
          });
          navigate('/');
        } else {
          notification.error({
            message: intl.formatMessage(messages.loginFailed),
            top: 96,
            duration: 2,
          });
          console.error(response);
        }
      },
      onError: error => {
        console.error(error);
        notification.error({
          message: intl.formatMessage(messages.loginFailed),
          description: error.name,
          top: 96,
          duration: 2,
        });
      },
    },
  );

  // automatically run authentication if API key is in store and isn't in URL query params
  useEffect(() => {
    if (!queryApiKey && apiKey)
      authenticate({
        context: {
          apiKey,
        } as AuthContext,
      });
  }, [apiKey, authenticate, queryApiKey]);

  const handleOnFinish = (values: { locationApiKey: string }) => {
    authenticate({
      context: {
        apiKey: values?.locationApiKey ?? '',
      } as AuthContext,
    });
  };

  return (
    <Layout headerContent={<FormattedMessage {...messages.loginHeader} />}>
      {loading ? (
        <LoadingSpinner>
          <FormattedMessage {...messages.loginLoading} />
        </LoadingSpinner>
      ) : (
        <FullWidthHeightSpace align="center" direction="vertical">
          <Title level={4}>
            <FormattedMessage {...messages.loginTitle} />
          </Title>
          <Form id="authForm" layout="horizontal" name="authForm" onFinish={handleOnFinish}>
            <FormItem
              initialValue={queryApiKey ?? apiKey ?? ''}
              name="locationApiKey"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage {...messages.passwordValidationMessage} />,
                },
              ]}
            >
              <Input placeholder={intl.formatMessage(messages.passwordPlaceholder)} />
            </FormItem>
            <Button htmlType="submit" type="primary">
              <FormattedMessage {...messages.signIn} />
            </Button>
          </Form>
        </FullWidthHeightSpace>
      )}
    </Layout>
  );
};
