import { VFC } from 'react';
import { Checkbox, Row } from 'antd';
import { useIntl } from 'react-intl';
import { InventoryMeterType, inventoryMeterTypeDescriptor } from '../../../enums/inventoryMeterType';

export interface MeterTypeMultiselectProps {
  items?: InventoryMeterType[];
  onChange: (checkedValue: InventoryMeterType[]) => void;
}

export const MeterTypeMultiselect: VFC<MeterTypeMultiselectProps> = props => {
  const { items, onChange } = props;
  const intl = useIntl();

  const options = Object.values(InventoryMeterType).map(key => ({
    label: intl.formatMessage(inventoryMeterTypeDescriptor[key]),
    value: key,
  }));

  return (
    <Row justify="center" style={{ marginBottom: 25, rowGap: 10 }}>
      <Checkbox.Group options={options} value={items} onChange={onChange} />
    </Row>
  );
};
