/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Card, Descriptions, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InventoryMeterOtherDetails } from '@enmon/commons/lib/tools/inventory_meter';
import { DESCRIPTION_TABLE_CONFIG } from '../../../../const';
import { OtherCard as messages } from '../../messages';

interface OtherCardProps {
  isLoading: boolean;
  typeDetailsData?: InventoryMeterOtherDetails;
}

export const OtherCard: VFC<OtherCardProps> = ({ isLoading, typeDetailsData }) => {
  const { device_category } = typeDetailsData ?? {};

  return (
    <Card loading={isLoading} title={<FormattedMessage {...messages.title} />}>
      <Space direction="vertical" size={36} style={{ width: '100%' }}>
        <Descriptions bordered column={DESCRIPTION_TABLE_CONFIG} contentStyle={{ minWidth: 100 }} layout="horizontal">
          {device_category && (
            <Descriptions.Item label={<FormattedMessage {...messages.device_category} />}>
              <FormattedMessage {...messages[device_category]} />
            </Descriptions.Item>
          )}
        </Descriptions>
      </Space>
    </Card>
  );
};
