import styled from 'styled-components';
import { Layout } from 'antd';
import { theme } from '../../../consts/theme';

const { colors } = theme;

export const StyledHeader = styled(Layout.Header)`
  background-color: ${colors.dark};
  color: ${colors.white} !important;
  font-size: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.55), 0px 3px 4px -3px ${colors.dark};
  z-index: 10;
  padding: 10px 40px;
  border-radius: 0 0 5px 5px;
  min-height: 90px;
`;
