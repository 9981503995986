import 'reset-css';
import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { QueryClientProvider } from 'react-query';
import { App } from './App';
import { LanguageProvider } from './contexts/language/LanguageProvider';
import { queryClient } from './services/queryClient';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
