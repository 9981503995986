/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Card, Descriptions, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InventoryMeterGasDetails } from '@enmon/commons/lib/tools/inventory_meter';
import { DESCRIPTION_TABLE_CONFIG } from '../../../../const';
import { GasCard as messages } from '../../messages';
import { inventoryMeterOrientationDescriptor } from '../../../../../../enums/inventoryMeterOrientation';

interface GasCardProps {
  isLoading: boolean;
  typeDetailsData?: InventoryMeterGasDetails;
}

export const GasCard: VFC<GasCardProps> = ({ isLoading, typeDetailsData }) => {
  const { device_category, diameter_nominal, g_size, lay_length, orientation, p_max, q_max, q_min } =
    typeDetailsData ?? {};

  return (
    <Card loading={isLoading} title={<FormattedMessage {...messages.title} />}>
      <Space direction="vertical" size={36} style={{ width: '100%' }}>
        <Descriptions bordered column={DESCRIPTION_TABLE_CONFIG} contentStyle={{ minWidth: 100 }} layout="horizontal">
          {device_category && (
            <Descriptions.Item label={<FormattedMessage {...messages.deviceCategory} />}>
              <FormattedMessage {...messages[device_category]} />
            </Descriptions.Item>
          )}
          {diameter_nominal && (
            <Descriptions.Item label={<FormattedMessage {...messages.diameterNominal} />}>
              {diameter_nominal}
            </Descriptions.Item>
          )}
          {orientation && (
            <Descriptions.Item label={<FormattedMessage {...messages.orientation} />}>
              <FormattedMessage {...inventoryMeterOrientationDescriptor[orientation]} />
            </Descriptions.Item>
          )}
          {lay_length && (
            <Descriptions.Item label={<FormattedMessage {...messages.layLength} />}>{lay_length} mm</Descriptions.Item>
          )}
          {g_size && (
            <Descriptions.Item label={<FormattedMessage {...messages.gSize} />}>
              {g_size} {`m${String.fromCharCode(179)}/h`}
            </Descriptions.Item>
          )}
          {p_max && <Descriptions.Item label={<FormattedMessage {...messages.pMax} />}>{p_max} MPa</Descriptions.Item>}
          {q_max && (
            <Descriptions.Item label={<FormattedMessage {...messages.qMax} />}>
              {q_max} {`m${String.fromCharCode(179)}/h`}
            </Descriptions.Item>
          )}
          {q_min && (
            <Descriptions.Item label={<FormattedMessage {...messages.qMin} />}>
              {q_min} {`m${String.fromCharCode(179)}/h`}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Space>
    </Card>
  );
};
