/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import gql from 'graphql-tag';
import { InventoryMeterType } from '../../../enums/inventoryMeterType';
import type { InventoryLocationBuilding } from '../../../types/inventory/inventoryBuilding';
import type { InventoryLocation } from '../../../types/inventory/inventoryLocation';

export const GET_LOCATION_DETAIL_DATA = gql`
  query inventoryLocation($id: ID!) {
    inventoryLocations(filters: { id: { eq: $id } }) {
      data {
        id
        attributes {
          #Basic data for LocationCard
          name
          avatar_url
          api_key
          address
          folder_drive_id
        }
      }
    }
  }
`;

export interface InventoryLocationVariables {
  id: string | number;
  limit: number;
  locationBuildings?: InventoryLocationBuilding[];
  meterTypes?: InventoryMeterType[];
  sort: string[];
  start: number;
  tenants?: string[];
}

export interface InventoryLocationData {
  inventoryLocations: {
    data: Pick<
      InventoryLocation,
      'id' | 'name' | 'api_key' | 'address' | 'avatar_url' | 'folder_drive_id' | 'backend_gateway_establishment_id'
    >[];
  };
}
