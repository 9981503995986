/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Card, Descriptions, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'ramda';
import { InventoryMeterElectricityDetails } from '@enmon/commons/lib/tools/inventory_meter';
import { DESCRIPTION_TABLE_CONFIG } from '../../../../const';
import { ElectricityCard as messages } from '../../messages';

interface ElectricityCardProps {
  isLoading: boolean;
  typeDetailsData?: InventoryMeterElectricityDetails;
}

export const ElectricityCard: VFC<ElectricityCardProps> = ({ isLoading, typeDetailsData }) => {
  const { phases, mounting, width, tariffs, device_category, mct, direct } = typeDetailsData ?? {};

  return (
    <Card loading={isLoading} title={<FormattedMessage {...messages.title} />}>
      <Space direction="vertical" size={36} style={{ width: '100%' }}>
        <Descriptions bordered column={DESCRIPTION_TABLE_CONFIG} contentStyle={{ minWidth: 100 }} layout="horizontal">
          {device_category && (
            <Descriptions.Item label={<FormattedMessage {...messages.deviceCategory} />}>
              <FormattedMessage {...messages[device_category]} />
            </Descriptions.Item>
          )}
          {phases && <Descriptions.Item label={<FormattedMessage {...messages.phases} />}>{phases}</Descriptions.Item>}
          {mounting && (
            <Descriptions.Item label={<FormattedMessage {...messages.mounting} />}>
              <FormattedMessage {...messages[mounting]} />
            </Descriptions.Item>
          )}
          {width && <Descriptions.Item label={<FormattedMessage {...messages.width} />}>{width}</Descriptions.Item>}
          {tariffs && (
            <Descriptions.Item label={<FormattedMessage {...messages.tariffs} />}>{tariffs}</Descriptions.Item>
          )}
          {!isNil(direct) && (
            <Descriptions.Item label={<FormattedMessage {...messages.isDirect} />}>
              {direct ? <FormattedMessage {...messages.yesDirect} /> : <FormattedMessage {...messages.noDirect} />}
            </Descriptions.Item>
          )}
          {mct && (
            <Descriptions.Item label={<FormattedMessage {...messages.measuringCurrentTransformer} />}>
              {mct}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Space>
    </Card>
  );
};
