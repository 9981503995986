/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import { Children, cloneElement, isValidElement, useEffect, useRef, useState, FC, CSSProperties } from 'react';
import { FloorplanWithBlob } from '../../types/floorplanWithBlob';
import { useObjectUrl } from '../../hooks/useObjectUrl';

function calculateImageBounds(floorplan: FloorplanWithBlob) {
  const { height, width } = floorplan;
  const lat = height / 100 / 2;
  const lng = width / 100 / 2;

  return {
    north: lat,
    south: -lat,
    east: lng,
    west: -lng,
  };
}

const initMap = (ref: HTMLDivElement) =>
  new window.google.maps.Map(ref, {
    zoom: 4,
    maxZoom: 20,
    center: { lat: 0, lng: 0 },
    mapTypeId: 'floorplan_coordinate',
    tilt: 0,
  });

interface FloorplanMapProps extends google.maps.MapOptions {
  floorplan: FloorplanWithBlob;
  style?: CSSProperties;
}

export const Floorplan: FC<FloorplanMapProps> = ({ floorplan, children, style }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (!map && ref.current) {
      setMap(initMap(ref.current));
    }
  }, [map, ref]);

  const backgroundUrl = useObjectUrl(floorplan.blob);

  useEffect(() => {
    if (!map) return () => {};

    const imageBounds = calculateImageBounds(floorplan);
    map.setOptions({
      restriction: {
        latLngBounds: imageBounds,
        strictBounds: false,
      },
    });
    const overlay = new window.google.maps.GroundOverlay(backgroundUrl, imageBounds);
    overlay.setMap(map);
    return () => overlay.setMap(null);
  }, [backgroundUrl, map, floorplan]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, child => (isValidElement(child) ? cloneElement(child, { map }) : null))}
    </>
  );
};
