import styled from 'styled-components';
import { Layout } from 'antd';
import { theme } from '../../../consts/theme';

export const StyledLayout = styled(Layout)`
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${theme.colors.lightGray};
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
