// Theme is js so craco.config has an acces to it.

module.exports = {
  theme: {
    colors: {
      main: '#16cc0f',
      dark: '#606060',
      complementary: '#ef82f3',
      triadicDark: '#8286f3',
      triadicLight: '#82f3ef',
      white: '#FFFFFF',
      lightGray: '#FAFAFA',
    },
  },
};
