import { FormattedMessage } from 'react-intl';
import { VFC } from 'react';
import { Descriptions, Skeleton, Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { inventoryMeterAccessibilityDescriptor } from '../../../../enums/inventoryMeterAccessibility';
import { inventoryMeterTypeDescriptor } from '../../../../enums/inventoryMeterType';
import { NEW_METER, VALUE_NOT_DEFINED } from '../../const';
import { Map as messages } from '../../messages';
import { selectedMeterAtom } from '../../../auth/atoms';

// TODO: change casted types to the correct types according to the prod level (ENMON-1361)
export const DrawerContent: VFC = () => {
  const selectedMeter = useRecoilValue(selectedMeterAtom);
  return selectedMeter === undefined ? (
    <Skeleton active />
  ) : (
    <Space direction="vertical">
      <Descriptions bordered column={{ xl: 1 }}>
        <Descriptions.Item label={<FormattedMessage {...messages.meterType} />}>
          <FormattedMessage {...(inventoryMeterTypeDescriptor[selectedMeter.meter_type] ?? VALUE_NOT_DEFINED)} />
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage {...messages.accessibility} />}>
          <FormattedMessage
            {...(inventoryMeterAccessibilityDescriptor[selectedMeter.accessibility] ?? VALUE_NOT_DEFINED)}
          />
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage {...messages.serialKey} />}>
          {selectedMeter.new_meter ? (
            <FormattedMessage {...NEW_METER} />
          ) : (
            selectedMeter.serial_number ?? <FormattedMessage {...VALUE_NOT_DEFINED} />
          )}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage {...messages.tenant} />}>
          {selectedMeter.tenant ?? <FormattedMessage {...VALUE_NOT_DEFINED} />}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage {...messages.lat} />}>
          {selectedMeter.lat ? selectedMeter.lat.toFixed(6) : <FormattedMessage {...VALUE_NOT_DEFINED} />}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage {...messages.lng} />}>
          {selectedMeter.lng ? selectedMeter.lng.toFixed(6) : <FormattedMessage {...VALUE_NOT_DEFINED} />}
        </Descriptions.Item>
      </Descriptions>

      <Link style={{ marginTop: '25px' }} to={`/inventory-meter-detail/${selectedMeter.id}`}>
        <Button size="middle" type="primary">
          <FormattedMessage {...messages.detail} />
        </Button>
      </Link>
    </Space>
  );
};
