import React from 'react';
import Highcharts from 'highcharts';
import { InventoryMeterType } from '@enmon/commons/lib/tools';
import { IntlShape, useIntl } from 'react-intl';
import { BaseChart } from '../../../../../../components/BaseChart';
import { inventoryMeterTypeDeviceDescriptor } from '../../../../../../enums/inventoryMeterType';

export interface MeterTypeChartProps {
  data: {
    name: InventoryMeterType;
    count: number;
  }[];
}

const getChartOptions = (data: MeterTypeChartProps['data'], intl: IntlShape): Highcharts.Options => ({
  chart: {
    height: 300,
  },
  tooltip: {
    formatter() {
      return `<b>${this.key}:</b> ${this.y.toString()} (${this.percentage.toFixed(2)}%)`;
    },
  },
  series: [
    {
      type: 'pie',
      data: data.map(item => ({
        name: inventoryMeterTypeDeviceDescriptor[item.name]
          ? intl.formatMessage(inventoryMeterTypeDeviceDescriptor[item.name])
          : 'Unknown',
        y: item.count,
      })),
    },
  ],
});

export const MeterTypeChart: React.VFC<MeterTypeChartProps> = ({ data = [] }) => {
  const intl = useIntl();
  const options = getChartOptions(data, intl);

  return <BaseChart options={options} />;
};
