// it provides the language context to app
import { useState, FC } from 'react';
import { Language } from '../../enums/language';
import { defaultLanguageContextState, LanguageContext } from './languageContext';

export const LanguageProvider: FC = ({ children }) => {
  const [language, setLanguage] = useState<Language>(defaultLanguageContextState.userLanguage);

  const handleLanguageChange = (changedLanguage: Language) => {
    setLanguage(changedLanguage);
    localStorage.setItem('language', changedLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{
        userLanguage: language,
        setUserLanguage: handleLanguageChange,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
