export enum InventoryMeterResistance {
  Pt100 = 'pt100',
  Pt500 = 'pt500',
  Pt1000 = 'pt1000',
}

export const inventoryMeterResistanceTranslated = {
  [InventoryMeterResistance.Pt100]: 'Pt 100',
  [InventoryMeterResistance.Pt500]: 'Pt 500',
  [InventoryMeterResistance.Pt1000]: 'Pt 1000',
};
