/* eslint-disable import/no-extraneous-dependencies */
import gql from 'graphql-tag';
import {
  InventoryMeterType,
  InventoryMeterElectricityDetails,
  InventoryMeterGasDetails,
  InventoryMeterHeatColdDetails,
  InventoryMeterOtherDetails,
  InventoryMeterWaterDetails,
} from '@enmon/commons/lib/tools/inventory_meter';

export const GET_INVENTORY_METER_TYPE_DETAILS = {
  [InventoryMeterType.Electricity]: gql`
    query meterDetails($inventoryMeterId: ID!) {
      typeDetails: inventoryMeterElectricities(filters: { inventory_meter: { id: { eq: $inventoryMeterId } } }) {
        data {
          id
          attributes {
            phases
            mounting
            width
            tariffs
            direct
            mct
            device_category
          }
        }
      }
    }
  `,
  [InventoryMeterType.Water]: gql`
    query meterDetails($inventoryMeterId: ID!) {
      typeDetails: inventoryMeterWaters(filters: { inventory_meter: { id: { eq: $inventoryMeterId } } }) {
        data {
          id
          attributes {
            diameter_nominal
            flange
            flowrate
            woltman
            lay_length
            orientation
            hot_water
            tube_material
          }
        }
      }
    }
  `,
  [InventoryMeterType.Gas]: gql`
    query meterDetails($inventoryMeterId: ID!) {
      typeDetails: inventoryMeterGases(filters: { inventory_meter: { id: { eq: $inventoryMeterId } } }) {
        data {
          id
          attributes {
            device_category
            diameter_nominal
            g_size
            lay_length
            orientation
            p_max
            q_max
            q_min
          }
        }
      }
    }
  `,
  [InventoryMeterType.Heat]: gql`
    query meterDetails($inventoryMeterId: ID!) {
      typeDetails: inventoryMeterHeatColds(filters: { inventory_meter: { id: { eq: $inventoryMeterId } } }) {
        data {
          id
          attributes {
            diameter_nominal
            flange
            flowrate
            installation_position
            lay_length
            orientation
            resistance
          }
        }
      }
    }
  `,
  [InventoryMeterType.Cold]: gql`
    query meterDetails($inventoryMeterId: ID!) {
      typeDetails: inventoryMeterHeatColds(filters: { inventory_meter: { id: { eq: $inventoryMeterId } } }) {
        data {
          id
          attributes {
            diameter_nominal
            flange
            flowrate
            installation_position
            lay_length
            orientation
            resistance
          }
        }
      }
    }
  `,
  [InventoryMeterType.Other]: gql`
    query meterDetails($inventoryMeterId: ID!) {
      typeDetails: inventoryMeterOthers(filters: { inventory_meter: { id: { eq: $inventoryMeterId } } }) {
        data {
          id
          attributes {
            device_category
          }
        }
      }
    }
  `,
};

export interface InventoryMeterTypeDetailsVariables {
  inventoryMeterId: string | number;
}

export interface InventoryMeterTypeDetailsData {
  typeDetails: {
    data:
      | InventoryMeterElectricityDetails[]
      | InventoryMeterWaterDetails[]
      | InventoryMeterGasDetails[]
      | InventoryMeterHeatColdDetails[]
      | InventoryMeterOtherDetails[];
  };
}
