import styled from 'styled-components';
import { Layout } from 'antd';
import { theme } from '../../../consts/theme';

export const StyledFooter = styled(Layout.Footer)`
  background-color: ${theme.colors.dark};

  box-sizing: border-box;
  padding: 15px;
  color: ${theme.colors.white};
  border-radius: 5px 5px 0 0;
  box-shadow: 0px -5px 4px -4px rgba(0, 0, 0, 0.35), 0px -3px 2px -3px ${theme.colors.dark};
`;
