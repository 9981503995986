import { useQuery } from 'react-query';
import { isNil } from 'ramda';
import { InventoryMeter } from '@enmon/commons/lib/tools/inventory_meter';
import { InventoryLocationBuilding } from '@enmon/commons/lib/tools/inventoryLocationBuilding';
import { getBuildingFloorplanApiClient } from '../../../../../services/api/buildingFloorplanApi';
import { FloorplanWithBlob } from '../../../../../types/floorplanWithBlob';

export type FloorplanShape = {
  data?: FloorplanWithBlob;
  error?: unknown;
  isLoading: boolean;
};

export const useFloorplan = (
  buildingFloor: InventoryMeter['building_floor'],
  locationBuildingId: InventoryLocationBuilding['id'],
): FloorplanShape => {
  const filter = { floor: buildingFloor, inventory_location_building: locationBuildingId };

  const { data, error, isLoading } = useQuery(
    ['inventory-location-building-floorplans', 'all', filter],
    async () => {
      const [floorplan] = await getBuildingFloorplanApiClient().find(filter);

      if (!floorplan) return null;

      const floorplanBlob = await getBuildingFloorplanApiClient().getContent(floorplan.id);

      return {
        ...floorplan,
        blob: floorplanBlob,
      } as FloorplanWithBlob;
    },
    {
      onError: err => console.error(err),
      enabled: !isNil(buildingFloor) && !isNil(locationBuildingId),
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};
