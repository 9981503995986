import { TablePaginationConfig } from 'antd';
import { useState, useMemo } from 'react';

export type TablePaginationShape = Pick<
  TablePaginationConfig,
  'pageSize' | 'current' | 'total' | 'onChange' | 'showSizeChanger' | 'showTotal'
>;

export const useTablePagination = (
  total: TablePaginationShape['total'],
  entityName?: string,
  defaultCurrentPage?: TablePaginationShape['current'],
  defaultPageSize?: TablePaginationShape['pageSize'],
): TablePaginationShape => {
  const [{ pageSize, currentPage }, setPageContainer] = useState({
    currentPage: defaultCurrentPage ?? 1,
    pageSize: defaultPageSize ?? 20,
  });

  const pagination = useMemo(
    () => ({
      pageSize,
      current: currentPage,
      total,
      onChange: (newPage: number, newPageSize: number) =>
        setPageContainer({ pageSize: newPageSize, currentPage: newPage }),
      showSizeChanger: true,
      showTotal: (currentTotal: number, range: [number, number]) =>
        `${range[0]}-${range[1]} of ${currentTotal} ${entityName ?? 'items'}`,
    }),
    [currentPage, entityName, pageSize, total],
  );

  return pagination;
};
