import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { mergeDeepRight } from 'ramda';
import styled from 'styled-components';
import { THEME } from '@enmon/commons';

export const ChartContainer = styled.div`
  max-width: 100%;
  width: 100%;
`;

type HighchartsReactWrapperProps = Pick<HighchartsReact.Props, 'options' | 'immutable'>;

export const HighchartsReactWrapper = React.forwardRef<HighchartsReact.RefObject, HighchartsReactWrapperProps>(
  ({ options, immutable }, ref) => (
    <HighchartsReact ref={ref} highcharts={Highcharts} immutable={immutable} options={options} />
  ),
);

HighchartsReactWrapper.displayName = 'HighchartsReactWrapper';

Highcharts.setOptions({
  colors: [
    THEME.color.charts.palletes.recordType.H2O,
    THEME.color.charts.palletes.recordType.EE,
    THEME.color.grey.grey10,
    THEME.color.charts.palletes.common.primary.seven,
  ],
  chart: {
    style: {
      color: THEME.color.grey.grey11,
      fontFamily: THEME.font.typography.bodyText.fontFamily,
    },
  },
  legend: {
    align: 'right',
    verticalAlign: 'bottom',
  },
  xAxis: {
    gridLineWidth: 1,
    gridLineColor: THEME.color.grey.grey1,
    lineColor: THEME.color.grey.grey1,
    minorGridLineColor: THEME.color.grey.grey1,
    tickColor: THEME.color.grey.grey1,
    tickWidth: 1,
  },
  yAxis: {
    gridLineColor: THEME.color.grey.grey1,
    lineColor: THEME.color.grey.grey1,
    minorGridLineColor: THEME.color.grey.grey1,
    tickColor: THEME.color.grey.grey1,
    tickWidth: 1,
  },
});

export const BaseChart: React.VFC<{
  options: Highcharts.Options;
  immutable?: boolean;
  chartRef?: React.RefObject<HighchartsReact.RefObject>;
}> = ({ options, chartRef = null, immutable = false }) => {
  const combinedOptions: Highcharts.Options = mergeDeepRight(
    {
      title: {
        text: null,
      },

      credits: {
        enabled: false,
      },

      exporting: {
        enabled: true,
        width: 1600,
        height: 900,
      },

      legend: {
        enabled: true,
      },
    },
    options,
  );

  return (
    <ChartContainer>
      <HighchartsReactWrapper ref={chartRef} immutable={immutable} options={combinedOptions} />
    </ChartContainer>
  );
};
