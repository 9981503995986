import { FC, ReactNode } from 'react';
import { BasicProps } from 'antd/lib/layout/layout';
import { Button, Col, Row, Select, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';
import { StyledLayout } from './components/StyledLayout';
import { StyledContent } from './components/StyledContent';
import { StyledHeader } from './components/StyledHeader';
import { StyledFooter } from './components/StyledFooter';
import { StyledContenWrapper } from './components/StyledContenWrapper';
import { RESPONSIVE_SPACINGS_CONFIG } from './const';
import { useLanguageContext } from '../../contexts/language/languageContext';
import { Language } from '../../enums/language';
import { getLanguage } from '../../utils/getLanguage';
import { apiKeyState, locationIdState, useIsAuthenticated } from '../../routes/auth/atoms';

export interface LayoutProps extends BasicProps {
  headerContent?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ headerContent, children }) => {
  const isAuthenticated = useIsAuthenticated();
  const setApiKey = useSetRecoilState(apiKeyState);
  const setLocationId = useSetRecoilState(locationIdState);
  const { setUserLanguage } = useLanguageContext();

  const handleLogout = () => {
    setApiKey(undefined);
    setLocationId(undefined);
    window.location.reload();
  };

  return (
    <StyledLayout>
      <Row justify="center" style={{ width: '100%', zIndex: 10 }}>
        <Col {...RESPONSIVE_SPACINGS_CONFIG} style={{ width: '100%' }}>
          <StyledHeader>
            {headerContent}
            {isAuthenticated && (
              <div style={{ marginLeft: 'auto' }}>
                <Tooltip title="Log Out">
                  <Button icon={<LogoutOutlined />} onClick={handleLogout} />
                </Tooltip>
              </div>
            )}
          </StyledHeader>
        </Col>
      </Row>

      <Row align="stretch" justify="center" style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <Col {...RESPONSIVE_SPACINGS_CONFIG} style={{ height: '100%', width: '100%' }}>
          <StyledContent>
            <StyledContenWrapper>{children}</StyledContenWrapper>
          </StyledContent>
        </Col>
      </Row>

      <Row justify="center" style={{ width: '100%' }}>
        <Col {...RESPONSIVE_SPACINGS_CONFIG} style={{ width: '100%' }}>
          <StyledFooter>
            <Row justify="space-between">
              <Col>
                <Select defaultValue={getLanguage() ?? Language.English} onChange={setUserLanguage}>
                  <Select.Option value={Language.Czech}>🇨🇿</Select.Option>
                  <Select.Option value={Language.English}>🇬🇧</Select.Option>
                </Select>
              </Col>
              <Col>Created by Enmon Technologies - {new Date().getFullYear()}</Col>
            </Row>
          </StyledFooter>
        </Col>
      </Row>
    </StyledLayout>
  );
};
