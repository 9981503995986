/* eslint-disable camelcase */
import React, { VFC } from 'react';
import { Space, Tabs } from 'antd';
import { useIntl } from 'react-intl';
import { MeterDetailTable } from './Table';
import { MapContent } from './map/Content';
import type { MeterRecord } from '../types/meterRecord';
import { TablePaginationShape } from '../../../hooks/useTablePagination';
import { SearchResultsCard as messages } from '../messages';
import { FilterArguments } from '../types/filterArguments';

const { TabPane } = Tabs;

interface SearchResultsCardProps {
  readonly data: MeterRecord[];
  readonly filter: FilterArguments;
  readonly paginationOptions: TablePaginationShape;
  readonly onMeterDetailEnter: (invetroyMeterId: string | number) => void;
}

export const SearchResultsCard: VFC<SearchResultsCardProps> = props => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data, filter, onMeterDetailEnter, paginationOptions } = props;
  const intl = useIntl();
  return (
    <Tabs defaultActiveKey="1">
      <TabPane key="1" tab={intl.formatMessage(messages.list)}>
        <Space className="w-100" direction="vertical" style={{ maxWidth: '100%' }}>
          <MeterDetailTable
            data={data}
            filter={filter}
            paginationOptions={paginationOptions}
            onMeterDetailEnter={onMeterDetailEnter}
          />
        </Space>
      </TabPane>
      <TabPane key="2" tab={intl.formatMessage(messages.map)}>
        <MapContent data={data} />
      </TabPane>
    </Tabs>
  );
};
