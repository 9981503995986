import { InventoryMeterAccessibility } from '@enmon/commons/lib/tools';
import { MessageDescriptor } from 'react-intl';

export const inventoryMeterAccessibilityDescriptor: Record<InventoryMeterAccessibility, MessageDescriptor> = {
  [InventoryMeterAccessibility.Good]: { id: 'app.enums.InventoryMeterAccessibility.Good' },
  [InventoryMeterAccessibility.Tenant]: { id: 'app.enums.InventoryMeterAccessibility.Tenant' },
  [InventoryMeterAccessibility.Basement]: { id: 'app.enums.InventoryMeterAccessibility.Basement' },
  [InventoryMeterAccessibility.Ceiling]: { id: 'app.enums.InventoryMeterAccessibility.Ceiling' },
  [InventoryMeterAccessibility.Shaft]: { id: 'app.enums.InventoryMeterAccessibility.Shaft' },
  [InventoryMeterAccessibility.FloodedShaft]: { id: 'app.enums.InventoryMeterAccessibility.FloodedShaft' },
  [InventoryMeterAccessibility.High]: { id: 'app.enums.InventoryMeterAccessibility.High' },
  [InventoryMeterAccessibility.VeryHigh]: { id: 'app.enums.InventoryMeterAccessibility.VeryHigh' },
  [InventoryMeterAccessibility.Bad]: { id: 'app.enums.InventoryMeterAccessibility.Bad' },
};
