/* eslint-disable no-undef */
import React, { useMemo } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { GOOGLE_MAPS_API_KEY, DEFAULT_LAT, DEFAULT_LNG } from '../../const';
import { Map } from './Map';
import { Marker } from './Marker';
import type { MeterRecord } from '../../types/meterRecord';

interface MapContentProps {
  readonly data: MeterRecord[];
}

export const MapContent: React.VFC<MapContentProps> = props => {
  const { data } = props;
  const position = useMemo(
    () => ({
      lat: data?.[0]?.lat ?? DEFAULT_LAT,
      lng: data?.[0]?.lng ?? DEFAULT_LNG,
    }),
    [data],
  );

  return (
    <Wrapper apiKey={GOOGLE_MAPS_API_KEY}>
      <Map
        center={position}
        fullscreenControl={false}
        streetViewControl={false}
        style={{ flexGrow: 1, height: 600 }}
        zoom={15}
      >
        {data?.length && data?.map(item => <Marker key={item.id} meter={item} />)}
      </Map>
    </Wrapper>
  );
};
