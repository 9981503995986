/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Badge, Card, Descriptions, Space, Tag } from 'antd';
import { useIntl } from 'react-intl';
import { InventoryMeterData } from '../queries/getInventoryMeterData';
import { DESCRIPTION_TABLE_CONFIG } from '../../../const';
import { getAccessibilityTagColor } from '../../../components/utils/getAccessibilityTagColor';
import { SectionMain as messages } from '../messages';
import { inventoryMeterTypeDeviceDescriptor } from '../../../../../enums/inventoryMeterType';
import { inventoryMeterAccessibilityDescriptor } from '../../../../../enums/inventoryMeterAccessibility';
import { inventoryMeterMountTypeTranslated } from '../../../../../enums/inventoryMeterMountType';

interface SectionMainProps {
  inventoryMeter: InventoryMeterData;
  isLoading: boolean;
}

export const SectionMain: VFC<SectionMainProps> = props => {
  const { inventoryMeter, isLoading } = props;
  const {
    accessibility,
    monitored_entity,
    building_floor,
    inventory_location_building,
    meter_type,
    owner,
    tenant,
    meter_mount_type,
    submeter,
    new_meter,
  } = inventoryMeter ?? {};
  const intl = useIntl();

  return (
    <Card loading={isLoading} title={intl.formatMessage(messages.main)}>
      <Space direction="vertical" size={36} style={{ width: '100%' }}>
        <Descriptions bordered column={DESCRIPTION_TABLE_CONFIG} contentStyle={{ minWidth: 100 }} layout="horizontal">
          {meter_type && (
            <Descriptions.Item label={intl.formatMessage(messages.meterType)}>
              {intl.formatMessage(inventoryMeterTypeDeviceDescriptor[meter_type])}
            </Descriptions.Item>
          )}
          <Descriptions.Item label={intl.formatMessage(messages.monitoredEntity)}>{monitored_entity}</Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.accessibility)}>
            {accessibility?.split(';')?.map(item => (
              <Tag key={item} color={getAccessibilityTagColor(item)}>
                {intl.formatMessage(inventoryMeterAccessibilityDescriptor[item])}
              </Tag>
            ))}
          </Descriptions.Item>

          <Descriptions.Item label={intl.formatMessage(messages.owner)}>{owner}</Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.tenant)}>{tenant}</Descriptions.Item>

          <Descriptions.Item label={intl.formatMessage(messages.building)}>
            {inventory_location_building?.name}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.buildingFloor)}>
            {building_floor ? `${building_floor}.` : intl.formatMessage(messages.groundFloor)}
          </Descriptions.Item>

          <Descriptions.Item label={intl.formatMessage(messages.isSubmeter)}>
            {submeter ? (
              <Badge status="success" text={intl.formatMessage(messages.yes)} />
            ) : (
              <Badge status="default" text={intl.formatMessage(messages.no)} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.isSuggestedNewMeter)}>
            {new_meter ? (
              <Badge status="success" text={intl.formatMessage(messages.yes)} />
            ) : (
              <Badge status="default" text={intl.formatMessage(messages.no)} />
            )}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.meterMountType)}>
            {meter_mount_type
              ? intl.formatMessage(inventoryMeterMountTypeTranslated[meter_mount_type] ?? { id: meter_mount_type })
              : ''}
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </Card>
  );
};
