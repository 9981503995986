import axios, { AxiosBasicCredentials, AxiosRequestConfig } from 'axios';
import { getApiKey } from '../utils/getApiKey';
import { getApiUrl } from '../utils/getApiUrl';

export interface BasicCredentials extends AxiosBasicCredentials {
  type: 'basic';
}

export interface HeaderCredentials {
  type: 'header';
  name: string;
  value: string;
}

export type Authenticator = () => BasicCredentials | HeaderCredentials;

const globalApiKeyAuthenticator: Authenticator = () => ({
  type: 'basic',
  username: 'InventoryLocation',
  password: getApiKey() ?? '',
});

export const customApiKeyAuthenticator =
  (apiKey: string): Authenticator =>
  () => ({
    type: 'basic',
    username: 'InventoryLocation',
    password: apiKey,
  });

export interface RequestConfig extends AxiosRequestConfig {
  /**
   * If set to null then request will be send without authorization header.
   * @default globalApiKeyAuthenticator
   */
  authenticator?: null | Authenticator;
}

export class HttpClient {
  protected readonly urlPrefix: string;

  constructor(urlPrefix: string) {
    this.urlPrefix = urlPrefix;
  }

  private getAxios({ authenticator = globalApiKeyAuthenticator }: RequestConfig) {
    const credentials = authenticator?.();

    return axios.create({
      baseURL: getApiUrl(this.urlPrefix).toString(),
      auth: credentials?.type === 'basic' ? credentials : undefined,
      headers: {
        ...(credentials?.type === 'header'
          ? {
              [credentials.name]: credentials.value,
            }
          : {}),
      },
    });
  }

  async get<T>(url: string, config: RequestConfig = {}): Promise<T> {
    try {
      const response = await this.getAxios(config).get<T>(url, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async post<T>(url: string, body: unknown, config: RequestConfig = {}): Promise<T> {
    try {
      const response = await this.getAxios(config).post<T>(url, body, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async put<T>(url: string, body: unknown, config: RequestConfig = {}): Promise<T> {
    try {
      const response = await this.getAxios(config).put<T>(url, body, config);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async delete<T>(url: string, options: RequestConfig = {}): Promise<T> {
    try {
      const response = await this.getAxios(options).delete<T>(url);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
