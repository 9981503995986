import { MessageDescriptor } from 'react-intl';

export enum InventoryMeterInstallationPosition {
  Forward = 'forward',
  Return = 'return',
}

export const inventoryMeterInstallationDescriptor: Record<InventoryMeterInstallationPosition, MessageDescriptor> = {
  [InventoryMeterInstallationPosition.Forward]: { id: 'app.enums.InventoryMeterInstallationPosition.Forward' },
  [InventoryMeterInstallationPosition.Return]: { id: 'app.enums.InventoryMeterInstallationPosition.Return' },
};
