/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Button } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { deserialize } from '@enmon/commons';
import { MeterDetailTable as messages } from '../messages';
import {
  GET_LOCATION_METERS_DATA,
  LocationMetersData,
  LocationMetersDataVariables,
} from '../queries/getLocationMetersData';
import { exportMetersToCsv } from './utils/exportMetersToCsv';
import { FilterArguments } from '../types/filterArguments';
import { useLocationId } from '../../auth/atoms';

export interface CsvExportButtonProps {
  filter: FilterArguments;
}

export const CsvExportButton: VFC<CsvExportButtonProps> = props => {
  const { filter } = props;
  const intl = useIntl();
  const locationId = useLocationId();
  const [getMeters, { loading: meterLoading }] = useLazyQuery<LocationMetersData, LocationMetersDataVariables>(
    GET_LOCATION_METERS_DATA,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted(response) {
        const { inventoryLocations, inventoryMeters } = deserialize<LocationMetersData>(response) ?? {};

        if (inventoryLocations.data?.[0]?.name && inventoryMeters?.data?.length) {
          exportMetersToCsv(inventoryLocations.data?.[0]?.name, inventoryMeters.data, intl);
        }
      },
    },
  );

  const fetchMeterData = () => {
    getMeters({
      variables: {
        locationBuildings: filter.buildings?.length ? filter.buildings : undefined,
        locationId,
        meterTypes: filter.meterTypes?.length ? filter.meterTypes : undefined,
        tenants: filter.tenants?.length ? filter.tenants : undefined,
      },
    });
  };

  return (
    <Button loading={meterLoading} type="primary" onClick={fetchMeterData}>
      <FormattedMessage {...messages.exportToCsv} />
    </Button>
  );
};
