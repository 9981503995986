import { MessageDescriptor } from 'react-intl';

export enum InventoryMeterOrientation {
  Horizontal = 'horizontal',
  VerticalFlowUp = 'verticalFlowUp',
  VerticalFlowDown = 'verticalFlowDown',
}

export const inventoryMeterOrientationDescriptor: Record<InventoryMeterOrientation, MessageDescriptor> = {
  [InventoryMeterOrientation.Horizontal]: { id: 'app.enums.InventoryMeterOrientation.Horizontal' },
  [InventoryMeterOrientation.VerticalFlowUp]: { id: 'app.enums.InventoryMeterOrientation.VerticalFlowUp' },
  [InventoryMeterOrientation.VerticalFlowDown]: { id: 'app.enums.InventoryMeterOrientation.VerticalFlowDown' },
};
