import { defineMessages } from 'react-intl';

export const AuthPage = defineMessages({
  loginSuccess: {
    id: 'app.auth.AuthPage.loginSuccess',
  },
  loginFailed: {
    id: 'app.auth.AuthPage.loginFailed',
  },
  loginError: {
    id: 'app.auth.AuthPage.loginError',
  },
  loginHeader: {
    id: 'app.auth.AuthPage.loginHeader',
  },
  loginLoading: {
    id: 'app.auth.AuthPage.loginLoading',
  },
  loginTitle: {
    id: 'app.auth.AuthPage.loginTitle',
  },
  passwordValidationMessage: {
    id: 'app.auth.AuthPage.passwordValidationMessage',
  },
  passwordPlaceholder: {
    id: 'app.auth.AuthPage.passwordPlaceholder',
  },
  signIn: {
    id: 'app.auth.AuthPage.signIn',
  },
});
