/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import type { InventoryLocationBuildingFloorplan } from '@enmon/commons/lib/tools';
import { BaseApiClient } from './baseApiClient';

class BuildingFloorplanApiClient extends BaseApiClient<InventoryLocationBuildingFloorplan> {
  constructor() {
    super('api/inventory-location-building-floorplans');
  }

  public async find(filter: Record<string, unknown>): Promise<InventoryLocationBuildingFloorplan[]> {
    const response = await this.http.get<InventoryLocationBuildingFloorplan[]>('', {
      params: filter,
    });

    return response;
  }

  public async getContent(id: InventoryLocationBuildingFloorplan['id']): Promise<Blob> {
    const response = await this.http.get<Blob>(`${id}/content`, {
      responseType: 'blob',
    });

    return response;
  }
}

let client: BuildingFloorplanApiClient;

export const getBuildingFloorplanApiClient = (): BuildingFloorplanApiClient =>
  client ?? (client = new BuildingFloorplanApiClient());
