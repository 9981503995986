/* eslint-disable camelcase */

export function constructInventoryAppURL(refURL: string | URL, api_key?: string): URL {
  const url = typeof refURL === 'string' ? new URL(refURL) : refURL;

  const inventoryAppURL = new URL(url.origin);

  if (url.hostname === 'localhost') {
    inventoryAppURL.port = '3000';
  }

  if (url.hostname.endsWith('enmon.tech')) {
    const [stage, enmon, tech] = url.hostname.split('.').slice(-3);
    const inventoryAppHostname = ['inventory', stage, enmon, tech].join('.');
    inventoryAppURL.hostname = inventoryAppHostname;
  }

  inventoryAppURL.searchParams.append('apiKey', api_key);

  return inventoryAppURL;
}
