/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { VFC } from 'react';
import { Card, Descriptions, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ClipboardButton } from './ClipboardButton';
import { constructInventoryAppURL } from './utils/constructInventoryAppLinkURL';
import { InventoryLocation } from '../../../types/inventory/inventoryLocation';
import { LocationCard as messages } from '../messages';

export interface LocationCardProps {
  location: Pick<InventoryLocation, 'id' | 'name' | 'address' | 'api_key' | 'folder_drive_id'> | undefined;
  onRefetchLocation: () => void;
  loading: boolean;
}

export const LocationCard: VFC<LocationCardProps> = props => {
  const { loading, location } = props;
  const { address, api_key, name } = location ?? {};

  return (
    <Card loading={loading} style={{ height: '100%' }} title={<FormattedMessage {...messages.cardTitle} />}>
      <Descriptions column={1} size="small">
        <Descriptions.Item label={<FormattedMessage {...messages.name} />}>{name}</Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage {...messages.address} />}>{address}</Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage {...messages.apiKey} />}>
          <Space>
            <span>{api_key}</span>
            <ClipboardButton
              disabled={!api_key}
              size="small"
              value={constructInventoryAppURL(window.location.href, api_key).toString()}
            />
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
