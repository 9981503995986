import type { InventoryLocation } from '@enmon/commons/lib/tools';
import { useQuery } from 'react-query';
import { getLocationsApiClient, LocationMetersSummary } from '../../../services/api/locationsApi';

interface UseLocationMetersSummaryShape {
  isFetching: boolean;
  data?: LocationMetersSummary;
}

export function useLocationMetersSummary(locationId: InventoryLocation['id']): UseLocationMetersSummaryShape {
  const { data, isFetching } = useQuery([locationId, 'meters summary'], () =>
    getLocationsApiClient().getMetersSummary(locationId),
  );

  return {
    data,
    isFetching,
  };
}
