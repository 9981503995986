import { atom, selector, useRecoilValue } from 'recoil';
import { getApiKey } from '../../utils/getApiKey';
import { MeterRecord } from '../inventory_location/types/meterRecord';

export const locationIdState = atom<number | undefined>({
  key: 'auth/locationId',
  default: undefined,
  effects_UNSTABLE: [() => sessionStorage.removeItem('locationId')],
});

export const useLocationId = (): number | undefined => useRecoilValue(locationIdState);

export const apiKeyState = atom<string | undefined>({
  key: 'auth/apiKey',
  default: getApiKey(),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet(apiKey => {
        if (apiKey) sessionStorage.setItem('apiKey', apiKey);
        else sessionStorage.removeItem('apiKey');
      });
    },
  ],
});

const isAuthenticatedState = selector({
  key: 'auth/isAuthenticated',
  get: ({ get }) => Boolean(get(apiKeyState) && get(locationIdState)),
});

export const useIsAuthenticated = (): boolean => useRecoilValue(isAuthenticatedState);

export const selectedMeterAtom = atom<MeterRecord | undefined>({
  key: 'selectedMeterAtom',
  default: undefined,
});
