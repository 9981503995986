/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-extraneous-dependencies */
// TODO: Resolve import/no-extraneous-dependencies -> https://enmontech.atlassian.net/browse/TOOLS-91
import React from 'react';
import { useQuery } from '@apollo/client';
import { PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { deserialize } from '@enmon/commons';
import {
  GET_INVENTORY_METER_DATA,
  InventoryLocationMeterData,
  InventoryMeterVariables,
} from './queries/getInventoryMeterData';
import { InventoryMeterDetailContent } from './components/DetailContent';
import { Layout } from '../../../../components/Layout/Layout';
import { useMeterTypeDetails } from './hooks/useMeterTypeDetails';
import { useFloorplan } from './hooks/useFloorplan';

export const InventoryMeterDetailPage: React.VFC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // Fetch data of selected Inventory meter
  const {
    data,
    loading: meterIsLoading,
    error,
  } = useQuery<InventoryLocationMeterData, InventoryMeterVariables>(GET_INVENTORY_METER_DATA, {
    variables: {
      id,
    },
  });

  const deserializedData = deserialize<InventoryLocationMeterData>(data);

  const inventoryMeter = deserializedData?.inventoryMeters?.data?.[0];

  const { data: typeDetailsData, isLoading: typeDetailsLoading } = useMeterTypeDetails(inventoryMeter ?? {});

  const { data: floorplan, isLoading: floorplanLoading } = useFloorplan(
    inventoryMeter?.building_floor,
    inventoryMeter?.inventory_location_building?.id,
  );

  if (error) return <p>Error: {JSON.stringify(error)}</p>;

  return (
    <Layout headerContent="Meter detail">
      <PageHeader
        title={inventoryMeter?.monitored_entity ?? 'Detail'}
        onBack={() => {
          navigate(-1);
        }}
      />
      <InventoryMeterDetailContent
        floorplan={floorplan}
        inventoryMeter={inventoryMeter}
        isLoading={meterIsLoading || typeDetailsLoading || floorplanLoading}
        typeDetailsData={typeDetailsData}
      />
    </Layout>
  );
};
