export enum Unit {
  kWh = 'KWH',
  MWh = 'MWH',
  m3 = 'M3',
  MJ = 'MJ',
  GJ = 'GJ',
  other = 'OTHER',
}

export const unitTranslated = {
  [Unit.kWh]: 'kWh',
  [Unit.MWh]: 'MWh',
  [Unit.m3]: `m${String.fromCharCode(179)}`,
  [Unit.MJ]: 'MJ',
  [Unit.GJ]: 'GJ',
  [Unit.other]: 'jiná',
};
