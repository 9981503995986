import { InventoryMeterAccessibility } from '@enmon/commons/lib/tools';

export const getAccessibilityTagColor = (accessibility: string): 'red' | 'green' | 'orange' | 'default' => {
  switch (accessibility) {
    case InventoryMeterAccessibility.Good:
      return 'green';
    case InventoryMeterAccessibility.FloodedShaft:
    case InventoryMeterAccessibility.VeryHigh:
    case InventoryMeterAccessibility.Bad:
      return 'red';
    case InventoryMeterAccessibility.Tenant:
    case InventoryMeterAccessibility.Shaft:
    case InventoryMeterAccessibility.High:
    case InventoryMeterAccessibility.Ceiling:
    case InventoryMeterAccessibility.Basement:
      return 'orange';
    default:
      return 'default';
  }
};
