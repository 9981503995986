import { MessageDescriptor } from 'react-intl';
import { Unit } from './unit';

export enum InventoryMeterType {
  Electricity = 'electricity',
  Water = 'water',
  Gas = 'gas',
  Heat = 'heat',
  Cold = 'cold',
  Other = 'other',
}

export enum InventoryMeterTypeColor {
  electricity = '#ff5555',
  electricity4q = '#ff5555',
  water = '#8fcbff',
  gas = '#85f382',
  heat = '#ffc157',
  cold = '#a98fff',
  other = '#b0b2c4',
}

export const inventoryMeterTypeDescriptor: Record<InventoryMeterType, MessageDescriptor> = {
  [InventoryMeterType.Electricity]: { id: 'app.enums.inventoryMeterTypeTranslated.Electricity' },
  [InventoryMeterType.Water]: { id: 'app.enums.inventoryMeterTypeTranslated.Water' },
  [InventoryMeterType.Gas]: { id: 'app.enums.inventoryMeterTypeTranslated.Gas' },
  [InventoryMeterType.Heat]: { id: 'app.enums.inventoryMeterTypeTranslated.Heat' },
  [InventoryMeterType.Cold]: { id: 'app.enums.inventoryMeterTypeTranslated.Cold' },
  [InventoryMeterType.Other]: { id: 'app.enums.inventoryMeterTypeTranslated.Other' },
};

export const inventoryMeterTypeDeviceDescriptor: Record<InventoryMeterType, MessageDescriptor> = {
  [InventoryMeterType.Electricity]: { id: 'app.enums.inventoryMeterTypeTranslatedToDevice.Electricity' },
  [InventoryMeterType.Water]: { id: 'app.enums.inventoryMeterTypeTranslatedToDevice.Water' },
  [InventoryMeterType.Gas]: { id: 'app.enums.inventoryMeterTypeTranslatedToDevice.Gas' },
  [InventoryMeterType.Heat]: { id: 'app.enums.inventoryMeterTypeTranslatedToDevice.Heat' },
  [InventoryMeterType.Cold]: { id: 'app.enums.inventoryMeterTypeTranslatedToDevice.Cold' },
  [InventoryMeterType.Other]: { id: 'app.enums.inventoryMeterTypeTranslatedToDevice.Other' },
};

export const inventoryMeterTypeDefaultUnit = {
  [InventoryMeterType.Electricity]: Unit.kWh,
  [InventoryMeterType.Water]: Unit.m3,
  [InventoryMeterType.Gas]: Unit.m3,
  [InventoryMeterType.Heat]: Unit.GJ,
  [InventoryMeterType.Cold]: Unit.GJ,
  [InventoryMeterType.Other]: Unit.GJ,
};
