import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionButton as messages } from '../messages';

interface ActionButtonProps {
  onDetailClick: () => void;
}

export const ActionButton: React.VFC<ActionButtonProps> = ({ onDetailClick }) => (
  <Button onClick={onDetailClick}>
    <FormattedMessage {...messages.detail} />
  </Button>
);
