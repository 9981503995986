import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BaseChart } from '../../../../../../components/BaseChart';

export interface BuildingSummaryChartProps {
  data: {
    name: string;
    count: number;
  }[];
}

const getChartOptions = (data: BuildingSummaryChartProps['data']): Highcharts.Options => ({
  chart: {
    height: 300,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'white',
        },
      },
    },
  },
  tooltip: {
    formatter() {
      return `<b>${this.key}:</b> ${this.y.toString()} (${this.percentage.toFixed(2)}%)`;
    },
  },
  series: [
    {
      type: 'pie',
      innerSize: '50%',
      data: data.map(item => ({
        name: item.name ?? 'Unknown',
        y: item.count,
      })),
    },
  ],
});

export const BuildingSummaryChart: React.VFC<BuildingSummaryChartProps> = ({ data = [] }) => {
  const options: Highcharts.Options = getChartOptions(data);
  const ref = React.useRef<HighchartsReact.RefObject>(null);

  return <BaseChart chartRef={ref} options={options} />;
};
