import { defineMessages } from 'react-intl';

export const Page = defineMessages({
  noMeters: {
    id: 'app.inventoryLocation.Page.noMeters',
  },
  meters: {
    id: 'app.inventoryLocation.Page.meters',
  },
});

export const ActionButton = defineMessages({
  detail: {
    id: 'app.inventoryLocation.ActionButton.detail',
  },
});

export const ClipboardButton = defineMessages({
  tooltip: {
    id: 'app.inventoryLocation.ClipboardButton.tooltip',
  },
});

export const Filter = defineMessages({
  buildings: {
    id: 'app.inventoryLocation.Filter.buildings',
  },
  buildingsPlaceholder: {
    id: 'app.inventoryLocation.Filter.buildingsPlaceholder',
  },
  tenants: {
    id: 'app.inventoryLocation.Filter.tenants',
  },
  tenantsPlaceholder: {
    id: 'app.inventoryLocation.Filter.tenantsPlaceholder',
  },
  search: {
    id: 'app.inventoryLocation.Filter.search',
  },
  reset: {
    id: 'app.inventoryLocation.Filter.reset',
  },
});

export const LocationCard = defineMessages({
  cardTitle: {
    id: 'app.inventoryLocation.LocationCard.cardTitle',
  },
  name: {
    id: 'app.inventoryLocation.LocationCard.name',
  },
  address: {
    id: 'app.inventoryLocation.LocationCard.address',
  },
  apiKey: {
    id: 'app.inventoryLocation.LocationCard.apiKey',
  },
});

export const LocationStatistics = defineMessages({
  cardTitle: {
    id: 'app.inventoryLocation.LocationStatistics.cardTitle',
  },
  totalMeters: {
    id: 'app.inventoryLocation.LocationStatistics.totalMeters',
  },
  metersInfo: {
    id: 'app.inventoryLocation.LocationStatistics.metersInfo',
  },
  existingMeters: {
    id: 'app.inventoryLocation.LocationStatistics.existingMeters',
  },
  noData: {
    id: 'app.inventoryLocation.LocationStatistics.noData',
  },
});

export const SearchResultsCard = defineMessages({
  list: {
    id: 'app.inventoryLocation.SearchResultsCard.list',
  },
  map: {
    id: 'app.inventoryLocation.SearchResultsCard.map',
  },
});

export const MeterDetailTable = defineMessages({
  strapiId: {
    id: 'app.inventoryLocation.MeterDetailTable.strapiId',
  },
  monitoredEntity: {
    id: 'app.inventoryLocation.MeterDetailTable.monitoredEntity',
  },
  meterType: {
    id: 'app.inventoryLocation.MeterDetailTable.meterType',
  },
  serialKey: {
    id: 'app.inventoryLocation.MeterDetailTable.serialKey',
  },
  building: {
    id: 'app.inventoryLocation.MeterDetailTable.building',
  },
  owner: {
    id: 'app.inventoryLocation.MeterDetailTable.owner',
  },
  tenant: {
    id: 'app.inventoryLocation.MeterDetailTable.tenant',
  },
  actions: {
    id: 'app.inventoryLocation.MeterDetailTable.actions',
  },
  exportToCsv: {
    id: 'app.inventoryLocation.MeterDetailTable.exportToCsv',
  },
});

export const Map = defineMessages({
  meterType: {
    id: 'app.inventoryLocation.Map.infoWindow.meterType',
  },
  serialKey: {
    id: 'app.inventoryLocation.Map.infoWindow.serialKey',
  },
  tenant: {
    id: 'app.inventoryLocation.Map.infoWindow.tenant',
  },
  accessibility: {
    id: 'app.inventoryLocation.Map.infoWindow.accessibility',
  },
  detail: {
    id: 'app.inventoryLocation.Map.infoWindow.detail',
  },
  lat: {
    id: 'app.inventoryLocation.Map.infoWindow.lat',
  },
  lng: {
    id: 'app.inventoryLocation.Map.infoWindow.lng',
  },
});

export const Csv = defineMessages({
  name: {
    id: 'app.inventoryLocation.Csv.name',
  },
  meterId: {
    id: 'app.inventoryLocation.Csv.meterId',
  },
  lat: {
    id: 'app.inventoryLocation.Csv.lat',
  },
  lng: {
    id: 'app.inventoryLocation.Csv.lng',
  },
  apiKey: {
    id: 'app.inventoryLocation.Csv.apiKey',
  },
  yes: {
    id: 'app.inventoryLocation.Csv.yes',
  },
  no: {
    id: 'app.inventoryLocation.Csv.no',
  },
});
