/* eslint-disable no-undef */
import { isNil } from 'ramda';
import { VFC, useEffect, useMemo } from 'react';

export const Marker: VFC<
  {
    infowindowContent?: string;
    onDragEnd?: (position: { lat: number; lng: number }) => void;
  } & google.maps.MarkerOptions
> = props => {
  const { infowindowContent, position, map, onDragEnd, ...rest } = props;

  const marker = useMemo(() => new google.maps.Marker({ draggable: !!onDragEnd }), [onDragEnd]);

  useEffect(() => () => marker.setMap(null), [marker]);

  useEffect(() => {
    if (marker) {
      const { lat, lng } = position;
      marker.setOptions({
        map,
        ...(!isNil(lat) && !isNil(lng) ? { position } : {}),
        ...rest,
      });
    }
  }, [marker, position, map, rest]);

  useEffect(() => {
    if (!marker || !onDragEnd) return () => {};

    const dragListener = marker.addListener('dragend', () => {
      const latLng = marker?.getPosition();
      if (!latLng) return;
      onDragEnd({ lat: latLng.lat(), lng: latLng.lng() });
    });

    return () => {
      dragListener.remove();
    };
  }, [marker, onDragEnd]);

  useEffect(() => {
    if (!marker || !infowindowContent) return () => {};

    const infowindow = new google.maps.InfoWindow({
      content: infowindowContent,
    });

    const clickListener = marker.addListener('click', () => {
      infowindow.open(map, marker);
    });

    return () => {
      clickListener.remove();
    };
  }, [marker, map, infowindowContent]);

  return null;
};
