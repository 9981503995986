/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { VFC, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import type { MeterRecord } from '../../types/meterRecord';
import { selectedMeterAtom } from '../../../auth/atoms';

export const Marker: VFC<google.maps.MarkerOptions & { meter: MeterRecord }> = ({ meter, map }) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const setSelectedMeter = useSetRecoilState(selectedMeterAtom);

  useEffect(() => {
    if (!meter.lat && !meter.lng) return undefined;

    const markerInstance = new google.maps.Marker({
      position: { lat: meter.lat, lng: meter.lng },
      title: 'Meter info',
    });

    markerInstance.addListener('click', () => {
      setSelectedMeter(meter);
    });

    setMarker(markerInstance);

    return () => markerInstance.setMap(null);
  }, []);

  useEffect(() => {
    if (marker && map) {
      marker.setMap(map);
    }
  }, [marker, map]);

  return null;
};
