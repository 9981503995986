/* eslint-disable camelcase */
import { StrictOmit } from '@enmon/commons';
import type {
  InventoryLocation,
  SerializedInventoryMeter,
  SerializedInventoryLocation,
  SerializedInventoryLocationBuilding,
} from '@enmon/commons/lib/tools';
import { BaseAPIParams } from '../../types/api/baseApi';
import { BaseApiClient } from './baseApiClient';

export interface Filter extends BaseAPIParams<InventoryLocation> {
  inventory?: number;
}

export type InventoryLocationDTO = StrictOmit<
  InventoryLocation,
  | 'id'
  | 'created_at'
  | 'updated_at'
  | 'avatar_url'
  | 'api_key'
  | 'backend_gateway_customer_id'
  | 'backend_gateway_establishment_id'
  | 'inventory_location_buildings'
  | 'inventory_meter'
> & {
  api_key?: InventoryLocation['api_key'] | null;
};

export type LocationMetersSummary = {
  inventory_location_building: {
    id: SerializedInventoryLocationBuilding['id'];
    name: SerializedInventoryLocationBuilding['name'];
    count: number;
  }[];
  submitted_by: {
    id: SerializedInventoryMeter['submitted_by'];
    name: SerializedInventoryMeter['submitted_by'];
    count: number;
  }[];
  meter_type: {
    id: SerializedInventoryMeter['meter_type'];
    name: SerializedInventoryMeter['meter_type'];
    count: number;
  }[];
};

export interface GenerateOrdersResponse {
  status: string;
  warnings: string[];
}

export interface ILocationsApiClient {
  getMetersSummary: (locationId: SerializedInventoryLocation['id']) => Promise<LocationMetersSummary>;
}

export class LocationsApiClient extends BaseApiClient<InventoryLocation> implements ILocationsApiClient {
  constructor() {
    super('api/inventory-locations');
  }

  public async getMetersSummary(locationId: SerializedInventoryLocation['id']): Promise<LocationMetersSummary> {
    const response = await this.http.get<LocationMetersSummary>(`${locationId}/meters-summary`);
    return response;
  }
}

let client: LocationsApiClient;

export const getLocationsApiClient = (): LocationsApiClient => client ?? (client = new LocationsApiClient());
