import { Card, Col, Image, Row, Skeleton } from 'antd';
import { VFC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { FALLBACK_IMAGE_URL } from '../../../const';
import { InventoryMeterData } from '../queries/getInventoryMeterData';
import { SectionPhotos as messages } from '../messages';
import { InventoryMeterPhoto } from '../../../../../types/inventory/inventoryMeterPhoto';
import { useObjectUrl } from '../../../../../hooks/useObjectUrl';
import { getInventoryMeterPhotosApi } from '../../../../../services/api/inventoryMeterPhotosApi';

const PHOTO_SIZE = 200 as const;

const Photo: VFC<{ blob: Blob }> = ({ blob }) => {
  const url = useObjectUrl(blob);
  return <Image alt="Inventory Meter Photo" fallback={FALLBACK_IMAGE_URL} height={PHOTO_SIZE} src={url} />;
};

const PhotoLoader: VFC<{ id: InventoryMeterPhoto['id'] }> = ({ id }) => {
  const {
    data: blob,
    error,
    isIdle,
    isLoading,
  } = useQuery(
    ['inventory-meter-photo', id, 'content'],
    async ({ signal }) => getInventoryMeterPhotosApi().getContent(id, { signal }),
    {
      onError: err => console.error(err),
    },
  );

  if (isIdle) return null;
  if (isLoading) return <Skeleton.Image style={{ height: PHOTO_SIZE, width: PHOTO_SIZE }} />;
  if (error || !blob)
    return <Image alt={error instanceof Error ? error.message : undefined} src={FALLBACK_IMAGE_URL} />;

  return <Photo blob={blob} />;
};

interface SectionPhotosProps {
  photos: InventoryMeterData['inventory_meter_photos'];
  isLoading: boolean;
}

export const SectionPhotos: VFC<SectionPhotosProps> = ({ photos, isLoading }) => (
  <Card loading={isLoading} title={<FormattedMessage {...messages.title} />}>
    <Image.PreviewGroup>
      <Row wrap gutter={[16, 16]} justify="space-around">
        {photos?.length ? (
          photos?.map(({ id }) => (
            <Col key={id}>
              <PhotoLoader id={id} />
            </Col>
          ))
        ) : (
          <FormattedMessage {...messages.missingPhotos} />
        )}
      </Row>
    </Image.PreviewGroup>
  </Card>
);
