import { createContext, useContext } from 'react';
import { Language } from '../../enums/language';
import { getLanguage } from '../../utils/getLanguage';

export interface LanguageContextShape {
  userLanguage: Language;
  setUserLanguage?: (newLanguage: Language) => void;
}

export const defaultLanguageContextState = {
  userLanguage: getLanguage() ?? Language.English,
};

// create the language context with default selected language
export const LanguageContext = createContext<LanguageContextShape>(defaultLanguageContextState);

export const useLanguageContext = (): LanguageContextShape => useContext(LanguageContext);
