/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Card, Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import { InventoryMeterHeatColdDetails } from '@enmon/commons/lib/tools';
import {
  InventoryMeterElectricityDetails,
  InventoryMeterGasDetails,
  InventoryMeterOtherDetails,
  InventoryMeterWaterDetails,
} from '@enmon/commons/lib/tools/inventory_meter';
import { InventoryMeterData } from '../queries/getInventoryMeterData';
import { InventoryMeterType } from '../../../../../enums/inventoryMeterType';
import { SectionMeterTypeSpecific as messages } from '../messages';

import { ColdHeatCard } from './type_details/ColdHeatCard';
import { ElectricityCard } from './type_details/ElectricityCard';
import { GasCard } from './type_details/GasCard';
import { WaterCard } from './type_details/WaterCard';
import { OtherCard } from './type_details/OtherCard';
import { InventoryMeterTypeDetailsData } from '../queries/getInventoryMeterTypeDetails';

interface SectionMeterTypeSpecificProps {
  inventoryMeter: InventoryMeterData;
  typeDetailsData: InventoryMeterTypeDetailsData['typeDetails']['data'][number] | Record<string, never>;
  isLoading: boolean;
}

export const SectionTypeDetails: VFC<SectionMeterTypeSpecificProps> = props => {
  const { inventoryMeter, typeDetailsData, isLoading } = props;
  const intl = useIntl();
  const { meter_type } = inventoryMeter ?? {};

  switch (meter_type) {
    case InventoryMeterType.Cold:
    case InventoryMeterType.Heat:
      return (
        <ColdHeatCard
          isLoading={isLoading}
          meterType={meter_type}
          typeDetailsData={typeDetailsData as InventoryMeterHeatColdDetails}
        />
      );
    case InventoryMeterType.Electricity:
      return (
        <ElectricityCard isLoading={isLoading} typeDetailsData={typeDetailsData as InventoryMeterElectricityDetails} />
      );
    case InventoryMeterType.Gas:
      return <GasCard isLoading={isLoading} typeDetailsData={typeDetailsData as InventoryMeterGasDetails} />;
    case InventoryMeterType.Other:
      return <OtherCard isLoading={isLoading} typeDetailsData={typeDetailsData as InventoryMeterOtherDetails} />;
    case InventoryMeterType.Water:
      return <WaterCard isLoading={isLoading} typeDetailsData={typeDetailsData as InventoryMeterWaterDetails} />;
    default:
      return (
        <Card loading={isLoading} title={intl.formatMessage(messages.title)}>
          <Row gutter={16}>
            <Col flex={1}>
              <div>
                {intl.formatMessage(messages.unsupportedMeterType, {
                  br: <br />,
                  email: <a href="mailto:info@enmon.tech">info@enmon.tech</a>,
                })}
              </div>
            </Col>
          </Row>
        </Card>
      );
  }
};
