import styled from 'styled-components';
import { Layout, LayoutProps } from 'antd';

interface StyledContentProps extends LayoutProps {
  $isOnline?: boolean;
}

export const StyledContent = styled(Layout.Content)<StyledContentProps>`
  background-color: transparent;
  box-shadow: '1px 0px 3px -2px rgba(0, 0, 0, 0.1), -1px 0px 3px -2px rgba(0, 0, 0, 0.1)';
  display: flex;
  justify-content: safe center;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
`;
