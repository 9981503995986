import { path, isEmpty, isNil } from 'ramda';

const createRow = (object: any, header: any) => {
  const values = [];

  header.forEach((field: any) => {
    let fieldValue: string = path([field.path], object);
    fieldValue = isEmpty(fieldValue) || isNil(fieldValue) ? '' : fieldValue.toString();
    fieldValue = fieldValue.replace(/"/g, '""');
    values.push(`"${fieldValue}"`);
  });

  return `${values.join(',')}\r\n`;
};

export function downloadCSV<Item>(name: string, header: { name: string; path: string }[], items: Item[]): void {
  if (!items.length) {
    return;
  }

  let csv = `${header.map(field => field.name).join(',')}\r\n`;

  items.forEach(item => {
    csv += createRow(item, header);
  });
  const URL = window.URL || window.webkitURL;

  const href =
    typeof URL.createObjectURL === 'undefined'
      ? `data:text/csv;charset=utf-8,\uFEFF${csv}`
      : URL.createObjectURL(new Blob(['\uFEFF', csv], { type: 'text/csv' }));

  const link = document.createElement('a');
  link.setAttribute('href', href);
  link.setAttribute('download', `${name}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
}
