/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card, Col, Empty, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { deserialize } from '@enmon/commons';
import {
  GET_LOCATION_DETAIL_DATA,
  InventoryLocationData,
  InventoryLocationVariables,
} from './queries/getLoactionDetailData';
import { Filter } from './components/Filter';
import { LocationCard } from './components/LocationCard';
import { AvatarCard } from './components/AvatarCard';
import { SearchResultsCard } from './components/SearchResultsCard';
import { Layout } from '../../components/Layout/Layout';
import { useTablePagination } from '../../hooks/useTablePagination';
import { Page as messages } from './messages';
import { FilterArguments } from './types/filterArguments';
import { useLocationId } from '../auth/atoms';
import { GET_METER_TABLE_DATA, InventoryMetersTableResponse } from './queries/getInventoryMetersTableData';
import { DEFAULT_FILTER } from './const';
import { useLocationMetersSummary } from './hooks/useLocationMetersSummary';
import { LocationMetersBanner } from './routes/inventory_meter_detail/components/Banner';

interface LocationStateShape {
  filter: FilterArguments;
  pageSize: number;
  current: number;
}

export const LocationDetailPage: React.VFC = () => {
  const navigate = useNavigate();

  const { state: locationState } = useLocation<LocationStateShape>();

  const intl = useIntl();

  const [filter, setFilter] = useState<FilterArguments>(locationState?.filter ?? DEFAULT_FILTER);

  const [total, setTotal] = useState<number>(0);

  const paginationOptions = useTablePagination(
    total,
    intl.formatMessage(messages.meters),
    locationState?.current,
    locationState?.pageSize,
  );
  const { pageSize, current } = paginationOptions;

  const {
    data: serializedLocation,
    loading,
    error,
    refetch,
  } = useQuery<InventoryLocationData, InventoryLocationVariables>(GET_LOCATION_DETAIL_DATA, {
    variables: {
      id: useLocationId(),
      limit: pageSize,
      locationBuildings: filter.buildings?.length ? filter.buildings : undefined,
      meterTypes: filter.meterTypes?.length ? filter.meterTypes : undefined,
      sort: ['id'],
      start: (current - 1) * pageSize,
      tenants: filter.tenants?.length ? filter.tenants : undefined,
    },
    fetchPolicy: 'network-only',
  });

  const { data: meters, loading: isMetersLoading } = useQuery<InventoryMetersTableResponse, InventoryLocationVariables>(
    GET_METER_TABLE_DATA,
    {
      variables: {
        id: useLocationId(),
        limit: pageSize,
        locationBuildings: filter.buildings?.length ? filter.buildings : undefined,
        meterTypes: filter.meterTypes?.length ? filter.meterTypes : undefined,
        sort: ['id'],
        start: (current - 1) * pageSize,
        tenants: filter.tenants?.length ? filter.tenants : undefined,
      },
      onCompleted(response) {
        setTotal(response?.inventoryMeters?.meta.pagination.total ?? 0);
      },
      fetchPolicy: 'network-only',
    },
  );
  const { inventoryMeters } = useMemo(() => deserialize<InventoryMetersTableResponse>(meters), [meters]) ?? {};

  const metersData = inventoryMeters?.data;

  const deserialisedLocation = useMemo(
    () => deserialize<InventoryLocationData>(serializedLocation),
    [serializedLocation],
  );
  const location = deserialisedLocation?.inventoryLocations?.data?.[0];
  const { name, avatar_url } = location ?? {};

  const handleDetail = (inventoryMeterID: string) => {
    navigate('', { replace: true, state: { filter, pageSize, current } });
    navigate(`/inventory-meter-detail/${inventoryMeterID}`);
  };

  const { data: summary, isFetching } = useLocationMetersSummary(useLocationId());

  if (error) return <p>Error: {JSON.stringify(error)}</p>;

  return (
    <Layout headerContent={name}>
      <>
        <Row wrap gutter={30} justify="space-between" style={{ marginBottom: 20, rowGap: 20 }}>
          {avatar_url && (
            <Col lg={24}>
              <AvatarCard avatar_url={avatar_url} loading={loading} name={name} />
            </Col>
          )}

          <Col lg={24}>
            <Filter defaultFields={filter} onFilter={setFilter} onReset={() => setFilter(DEFAULT_FILTER)} />
          </Col>

          <Col lg={16} md={24} xl={16} xxl={18}>
            <LocationMetersBanner loading={isFetching} summary={summary} />
          </Col>

          <Col lg={8} md={24} xl={8} xxl={6}>
            <LocationCard loading={loading} location={location} onRefetchLocation={refetch} />
          </Col>
        </Row>

        <Card loading={isMetersLoading} style={{ marginBottom: 20 }}>
          {metersData?.length ? (
            <SearchResultsCard
              data={metersData}
              filter={filter}
              paginationOptions={paginationOptions}
              onMeterDetailEnter={handleDetail}
            />
          ) : (
            <Empty>
              <FormattedMessage {...messages.noMeters} />
            </Empty>
          )}
        </Card>
      </>
    </Layout>
  );
};
