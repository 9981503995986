/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Card, Descriptions, Space } from 'antd';
import { useIntl } from 'react-intl';
import { InventoryMeterData } from '../queries/getInventoryMeterData';
import { DESCRIPTION_TABLE_CONFIG, NEW_METER } from '../../../const';
import { SectionDetails as messages } from '../messages';

interface SectionDetailsProps {
  inventoryMeter: InventoryMeterData;
  isLoading: boolean;
}

export const SectionDetails: VFC<SectionDetailsProps> = props => {
  const { inventoryMeter, isLoading } = props;
  const intl = useIntl();
  const { meter_model, new_meter, calibrated, manufactured, serial_number } = inventoryMeter ?? {};

  return (
    <Card loading={isLoading} title={intl.formatMessage(messages.title)}>
      <Space direction="vertical" size={36} style={{ width: '100%' }}>
        <Descriptions bordered column={DESCRIPTION_TABLE_CONFIG} contentStyle={{ minWidth: 100 }} layout="horizontal">
          <Descriptions.Item label={intl.formatMessage(messages.manufacturer)}>
            {meter_model?.manufacturer?.name}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.meterModel)}>{meter_model?.name}</Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.serialNumber)}>
            {new_meter ? intl.formatMessage(NEW_METER) : serial_number}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.manufactured)}>{manufactured}</Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage(messages.calibrated)}>{calibrated}</Descriptions.Item>
        </Descriptions>
      </Space>
    </Card>
  );
};
