/* eslint-disable camelcase */
import { VFC } from 'react';
import { Space, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { MeterRecord } from '../types/meterRecord';
import { ActionButton } from './ActionButton';
import { TablePaginationShape } from '../../../hooks/useTablePagination';
import { MeterDetailTable as messages } from '../messages';
import { inventoryMeterTypeDeviceDescriptor } from '../../../enums/inventoryMeterType';
import { NEW_METER } from '../const';
import { CsvExportButton } from './CsvExportButton';
import { FilterArguments } from '../types/filterArguments';

const getColumns = (onDetail: (inventoryMeterID: string | number) => void) => [
  {
    width: 60,
    title: <FormattedMessage {...messages.strapiId} />,
    dataIndex: ['id'],
    key: 'id',
  },
  {
    width: 160,
    title: <FormattedMessage {...messages.monitoredEntity} />,
    dataIndex: ['monitored_entity'],
    key: 'monitored_entity',
  },
  {
    width: 120,
    title: <FormattedMessage {...messages.meterType} />,
    render: (item: MeterRecord) => <FormattedMessage {...inventoryMeterTypeDeviceDescriptor[item.meter_type]} />,
    key: 'meter_type',
  },
  {
    width: 120,
    title: <FormattedMessage {...messages.serialKey} />,
    key: 'serial_number',
    render: (item: MeterRecord) => (item.new_meter ? <FormattedMessage {...NEW_METER} /> : item.serial_number),
  },
  {
    width: 100,
    title: <FormattedMessage {...messages.building} />,
    dataIndex: ['inventory_location_building', 'name'],
    key: 'inventory_location_building',
  },
  {
    width: 100,
    title: <FormattedMessage {...messages.owner} />,
    dataIndex: ['owner'],
    key: 'owner',
  },
  {
    width: 100,
    title: <FormattedMessage {...messages.tenant} />,
    dataIndex: ['tenant'],
    key: 'tenant',
  },
  {
    width: 100,
    title: <FormattedMessage {...messages.actions} />,
    key: 'action',
    fixed: 'right' as const,
    render: (item: MeterRecord) => (
      <Space>
        <ActionButton onDetailClick={() => onDetail(item.id)} />
      </Space>
    ),
  },
];

interface MeterDetailTableProps {
  data: MeterRecord[];
  filter: FilterArguments;
  onMeterDetailEnter: (invetroyMeterId: string | number) => void;
  paginationOptions: TablePaginationShape;
}

export const MeterDetailTable: VFC<MeterDetailTableProps> = props => {
  const { data, filter, onMeterDetailEnter, paginationOptions } = props;
  const columns = getColumns(onMeterDetailEnter);

  return (
    <Space className="w-100" direction="vertical" style={{ maxWidth: '100%' }}>
      <CsvExportButton filter={filter} />
      <Table
        columns={columns}
        dataSource={data}
        pagination={paginationOptions}
        rowKey={meter => meter.id}
        scroll={{ y: 600 }}
      />
    </Space>
  );
};
