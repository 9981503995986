import React, { useState, useMemo } from 'react';
import { Button, Select, Form, Card, Row, Col, Space } from 'antd';
import { uniq } from 'ramda';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { deserialize } from '@enmon/commons';
import { MeterTypeMultiselect } from './MeterTypeMultiselect';
import type { FilterArguments } from '../types/filterArguments';
import { Filter as messages } from '../messages';
import { FilterData, FilterVariables, GET_TENANTS_AND_BUILDINGS } from '../queries/getTenantsAndBuildings';
import { InventoryMeterType } from '../../../enums/inventoryMeterType';
import { useLocationId } from '../../auth/atoms';
import { DEFAULT_FILTER } from '../const';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export interface FilterProps {
  onFilter: (item: FilterArguments) => void;
  onReset: () => void;
  defaultFields: FilterArguments;
}

export const Filter: React.VFC<FilterProps> = props => {
  const { onFilter, onReset, defaultFields } = props;
  const [selectedMeterTypes, setSelectedMeterTypes] = useState<InventoryMeterType[]>(defaultFields.meterTypes);
  const [form] = Form.useForm();

  const {
    data: buildings,
    loading,
    error,
  } = useQuery<FilterData, FilterVariables>(GET_TENANTS_AND_BUILDINGS, {
    variables: {
      id: useLocationId(),
    },
  });
  const { inventoryLocations, inventoryMeters } = useMemo(() => deserialize<FilterData>(buildings), [buildings]) ?? {};

  const buildingsData = inventoryLocations?.data.length
    ? inventoryLocations?.data?.[0]?.inventory_location_buildings
    : [];

  const tenantsData = uniq(inventoryMeters?.data ?? []);

  if (error) return <p>Error: {JSON.stringify(error)}</p>;

  const buildingsOptions = buildingsData?.map(building => ({
    label: building.name,
    value: building.name,
  }));

  const tenantsOptions = tenantsData?.map(item => ({
    label: item.tenant,
    value: item.tenant,
  }));

  const handleFormReset = () => {
    setSelectedMeterTypes(DEFAULT_FILTER.meterTypes);
    form.setFieldsValue(DEFAULT_FILTER);
    onReset();
  };

  return (
    <Card loading={loading} style={{ marginBottom: 20 }}>
      <Form
        {...layout}
        form={form}
        initialValues={defaultFields}
        onFinish={values => onFilter({ ...values, meterTypes: selectedMeterTypes })}
      >
        <Row>
          <Col lg={20} sm={24}>
            <MeterTypeMultiselect items={selectedMeterTypes} onChange={setSelectedMeterTypes} />
            <Form.Item label={<FormattedMessage {...messages.buildings} />} name="buildings">
              <Select
                allowClear
                showArrow
                maxTagCount={3}
                maxTagTextLength={20}
                mode="multiple"
                options={buildingsOptions}
                placeholder={<FormattedMessage {...messages.buildingsPlaceholder} />}
                style={{ minWidth: 250, width: '70%' }}
              />
            </Form.Item>
            <Form.Item label={<FormattedMessage {...messages.tenants} />} name="tenants">
              <Select
                allowClear
                showArrow
                maxTagCount={3}
                maxTagTextLength={20}
                mode="multiple"
                options={tenantsOptions}
                placeholder={<FormattedMessage {...messages.tenantsPlaceholder} />}
                style={{ minWidth: 250, width: '70%' }}
              />
            </Form.Item>
          </Col>

          <Col lg={2} sm={24}>
            <Row gutter={25} justify="center">
              <Space>
                <Col>
                  <Button htmlType="submit" type="primary">
                    <FormattedMessage {...messages.search} />
                  </Button>
                </Col>

                <Col>
                  <Button htmlType="button" onClick={handleFormReset}>
                    <FormattedMessage {...messages.reset} />
                  </Button>
                </Col>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
