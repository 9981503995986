import { VFC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthPage } from './routes/auth/AuthPage';
import { NotFoundPage } from './routes/not_found/NotFoundPage';
import { LocationsDetailRouter } from './routes/inventory_location/Router';
import { useIsAuthenticated } from './routes/auth/atoms';

export const RootRouter: VFC = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={isAuthenticated ? <LocationsDetailRouter /> : <AuthPage />} path="/*" />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </BrowserRouter>
  );
};
