/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { VFC } from 'react';
import { Card, Descriptions, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InventoryMeterHeatColdDetails, InventoryMeterType } from '@enmon/commons/lib/tools/inventory_meter';
import { isNil } from 'ramda';
import { DESCRIPTION_TABLE_CONFIG } from '../../../../const';
import { ColdHeatCard as messages } from '../../messages';
import { inventoryMeterInstallationDescriptor } from '../../../../../../enums/inventoryMeterInstallationPosition';
import { inventoryMeterOrientationDescriptor } from '../../../../../../enums/inventoryMeterOrientation';
import { inventoryMeterResistanceTranslated } from '../../../../../../enums/inventoryMeterResistance';

interface ColdHeatCardProps {
  isLoading: boolean;
  meterType: InventoryMeterType;
  typeDetailsData: InventoryMeterHeatColdDetails;
}

export const ColdHeatCard: VFC<ColdHeatCardProps> = ({ isLoading, typeDetailsData }) => {
  const { diameter_nominal, flange, flowrate, installation_position, lay_length, orientation, resistance } =
    typeDetailsData ?? {};

  return (
    <Card loading={isLoading} title={<FormattedMessage {...messages.title} />}>
      <Space direction="vertical" size={36} style={{ width: '100%' }}>
        <Descriptions bordered column={DESCRIPTION_TABLE_CONFIG} contentStyle={{ minWidth: 100 }} layout="horizontal">
          {!isNil(flange) && (
            <Descriptions.Item label={<FormattedMessage {...messages.flange} />}>
              {flange ? <FormattedMessage {...messages.yes} /> : <FormattedMessage {...messages.no} />}
            </Descriptions.Item>
          )}
          {diameter_nominal && (
            <Descriptions.Item label={<FormattedMessage {...messages.diameterNominal} />}>
              {diameter_nominal}
            </Descriptions.Item>
          )}
          {flowrate && (
            <Descriptions.Item label={<FormattedMessage {...messages.flowrate} />}>
              {flowrate} {`m${String.fromCharCode(179)}/h`}
            </Descriptions.Item>
          )}
          {lay_length && (
            <Descriptions.Item label={<FormattedMessage {...messages.layLength} />}>{lay_length} mm</Descriptions.Item>
          )}
          {orientation && (
            <Descriptions.Item label={<FormattedMessage {...messages.orientation} />}>
              <FormattedMessage {...inventoryMeterOrientationDescriptor[orientation]} />
            </Descriptions.Item>
          )}
          {installation_position && (
            <Descriptions.Item label={<FormattedMessage {...messages.installationPosition} />}>
              <FormattedMessage {...inventoryMeterInstallationDescriptor[installation_position]} />
            </Descriptions.Item>
          )}
          {resistance && (
            <Descriptions.Item label={<FormattedMessage {...messages.resistance} />}>
              {inventoryMeterResistanceTranslated[resistance]}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Space>
    </Card>
  );
};
