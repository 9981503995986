import { FC } from 'react';
import { Spin } from 'antd';

export const LoadingSpinner: FC = ({ children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <Spin size="large" tip={children ?? ''} />
  </div>
);
