import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ContentWrapper } from './components/ContentWrapper';
import { Layout } from '../../components/Layout/Layout';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Layout headerContent="PAGE NOT FOUND">
      <ContentWrapper>
        <span>
          Smutné zprávy, stranka neexistuje a nebo není dočasně k dispozici.
          <span aria-label="Scared emoji" role="img">
            😱
          </span>
          ....
        </span>

        <span> Zkus obnovit stránku, popřípadě se vrať zpátky.</span>

        <Button
          type="primary"
          onClick={() => {
            navigate('/');
          }}
        >
          Jít zpět
        </Button>
      </ContentWrapper>
    </Layout>
  );
};
