import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LocationDetailPage } from './Page';
import { InventoryMeterDetailPage } from './routes/inventory_meter_detail/Page';

export const LocationsDetailRouter: FC = () => (
  <Routes>
    <Route element={<InventoryMeterDetailPage />} path="inventory-meter-detail/:id" />
    <Route element={<LocationDetailPage />} path="" />
  </Routes>
);
