/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { VFC } from 'react';
import { Card, Row } from 'antd';
import QRCode from 'react-qr-code';
import { useIntl } from 'react-intl';
import { InventoryMeterApiKey } from '../../../../../types/inventory/inventoryApiKey';
import { SectionApiKey as messages } from '../messages';

interface InventoryMeterFormMainSelectsProps {
  isLoading: boolean;
  inventory_meter_api_key: Pick<InventoryMeterApiKey, 'id' | 'api_key'>;
}

export const SectionApiKey: VFC<InventoryMeterFormMainSelectsProps> = props => {
  const { isLoading, inventory_meter_api_key } = props;
  const apiKey = inventory_meter_api_key?.api_key;
  const intl = useIntl();
  return (
    <Card bordered loading={isLoading} title={intl.formatMessage(messages.title)}>
      <Row justify="center" style={{ width: '100%' }}>
        {apiKey ? <QRCode value={apiKey} /> : intl.formatMessage(messages.missingQR)}
      </Row>
    </Card>
  );
};
