import { VFC } from 'react';
import { Card, Col, Row } from 'antd';
import { MeterTypeChart } from './charts/MeterTypeChart';
import { BuildingSummaryChart } from './charts/BuildingSummaryChart';
import { LocationMetersSummary } from '../../../../../services/api/locationsApi';

export const LocationMetersBanner: VFC<{
  summary?: LocationMetersSummary;
  loading: boolean;
}> = ({ summary = {}, loading }) => (
  <Row gutter={8}>
    <Col span={12}>
      <Card loading={loading}>
        <MeterTypeChart data={summary.meter_type} />
      </Card>
    </Col>
    <Col span={12}>
      <Card loading={loading}>
        <BuildingSummaryChart data={summary.inventory_location_building} />
      </Card>
    </Col>
  </Row>
);
