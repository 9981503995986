/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import { VFC } from 'react';
import { Col, Row, Space } from 'antd';
import { isNil } from 'ramda';
import { useIntl } from 'react-intl';
import { SectionMain } from './SectionMain';
import { SectionApiKey } from './SectionApiKey';
import { SectionDetails } from './SectionDetails';
import { SectionTypeDetails } from './SectionTypeDetails';
import { SectionPhotos } from './SectionPhotos';
import type { InventoryMeterData } from '../queries/getInventoryMeterData';
import { Marker, Map, Wrapper, Floorplan } from '../../../../../components/map';
import { GOOGLE_MAPS_API_KEY } from '../../../const';
import { FloorplanWithBlob } from '../../../../../types/floorplanWithBlob';
import { InventoryMeterDetails } from '../../../../../types/inventory/inventoryMeterDetails';
import { Map as messages } from '../../../messages';

interface InventoryMeterDetailContentProps {
  inventoryMeter: InventoryMeterData;
  typeDetailsData: InventoryMeterDetails;
  isLoading: boolean;
  floorplan?: FloorplanWithBlob;
}

export const InventoryMeterDetailContent: VFC<InventoryMeterDetailContentProps> = props => {
  const { inventoryMeter, typeDetailsData, floorplan, isLoading } = props;
  const intl = useIntl();

  const { lat, lng, floorplan_lat, floorplan_lng, inventory_meter_api_key, inventory_meter_photos } =
    inventoryMeter ?? {};

  return (
    <Row gutter={16}>
      <Col lg={16} md={24} sm={24} xl={18}>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <SectionMain inventoryMeter={inventoryMeter} isLoading={isLoading} />

          <SectionDetails inventoryMeter={inventoryMeter} isLoading={isLoading} />

          <SectionTypeDetails inventoryMeter={inventoryMeter} isLoading={isLoading} typeDetailsData={typeDetailsData} />

          <SectionPhotos isLoading={isLoading} photos={inventory_meter_photos} />
        </Space>
      </Col>

      <Col lg={8} md={24} sm={24} xl={6}>
        <Space wrap direction="vertical" size={16} style={{ width: '100%', minWidth: 273.64 }}>
          <SectionApiKey inventory_meter_api_key={inventory_meter_api_key} isLoading={isLoading} />

          {!isNil(lat) && !isNil(lng) && (
            <Wrapper apiKey={GOOGLE_MAPS_API_KEY}>
              <Map center={{ lat, lng }} style={{ flexGrow: 1, height: 400 }}>
                <Marker
                  infowindowContent={`
                    ${intl.formatMessage(messages.lat)}: ${lat.toFixed(6)} <br />
                    ${intl.formatMessage(messages.lng)}: ${lng.toFixed(6)}
                  `}
                  position={{ lat, lng }}
                />
              </Map>
            </Wrapper>
          )}

          {!isNil(floorplan_lat) && !isNil(floorplan_lng) && floorplan && (
            <Wrapper apiKey={GOOGLE_MAPS_API_KEY}>
              <Floorplan floorplan={floorplan} style={{ flexGrow: 1, height: 400 }}>
                <Marker position={{ lat: floorplan_lat, lng: floorplan_lng }} />
              </Floorplan>
            </Wrapper>
          )}
        </Space>
      </Col>
    </Row>
  );
};
