import { InventoryMeterPhoto } from '../../types/inventory/inventoryMeterPhoto';
import { RequestConfig } from '../httpClient';
import { BaseApiClient } from './baseApiClient';

class InventoryMeterPhotosApi extends BaseApiClient<InventoryMeterPhoto> {
  constructor() {
    super('api/inventory-meter-photos');
  }

  public async getContent(id: InventoryMeterPhoto['id'], config: Pick<RequestConfig, 'signal'> = {}): Promise<Blob> {
    return await this.http.get<Blob>(`${id}/content`, {
      responseType: 'blob',
      ...config,
    });
  }
}

let instance: InventoryMeterPhotosApi;

export const getInventoryMeterPhotosApi = (): InventoryMeterPhotosApi =>
  instance ?? (instance = new InventoryMeterPhotosApi());
