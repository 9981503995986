/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import { InventoryMeter, InventoryMeterApiKey, MeterModel, SerializedManufacturer } from '@enmon/commons/lib/tools';
import gql from 'graphql-tag';
import { InventoryMeterType } from '../../../enums/inventoryMeterType';
import { InventoryLocationBuilding } from '../../../types/inventory/inventoryBuilding';

export const GET_LOCATION_METERS_DATA = gql`
  query data($locationId: ID!, $locationBuildings: [String], $meterTypes: [String], $tenants: [String]) {
    inventoryLocations(filters: { id: { eq: $locationId } }) {
      data {
        id
        attributes {
          name
        }
      }
    }

    inventoryMeters(
      pagination: { limit: -1 }
      filters: {
        inventory_location: { id: { eq: $locationId } }
        inventory_location_building: { name: { in: $locationBuildings } }
        meter_type: { in: $meterTypes }
        tenant: { in: $tenants }
      }
    ) {
      data {
        id
        attributes {
          accessibility
          building_floor
          calibrated
          meter_mount_type
          submeter
          new_meter
          lat
          lng
          manufactured
          meter_type
          monitored_entity
          owner
          serial_number
          tenant

          inventory_location_building {
            data {
              id
              attributes {
                name
              }
            }
          }

          inventory_meter_api_key {
            data {
              id
              attributes {
                api_key
              }
            }
          }

          meter_model {
            data {
              id
              attributes {
                name
                manufacturer {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }

          meter_model {
            data {
              id
              attributes {
                name
                manufacturer {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type MeterFieldsData = Pick<
  InventoryMeter,
  | 'accessibility'
  | 'building_floor'
  | 'calibrated'
  | 'id'
  | 'meter_mount_type'
  | 'submeter'
  | 'new_meter'
  | 'lat'
  | 'lng'
  | 'manufactured'
  | 'meter_type'
  | 'monitored_entity'
  | 'owner'
  | 'serial_number'
  | 'tenant'
> & {
  inventory_location_building?: Pick<InventoryLocationBuilding, 'id' | 'name'>;
  inventory_meter_api_key?: Pick<InventoryMeterApiKey, 'id' | 'api_key'>;
  meter_model?: Pick<MeterModel, 'id' | 'name'> & {
    manufacturer?: Pick<SerializedManufacturer, 'id' | 'name'>;
  };
};

export interface LocationMetersDataVariables {
  locationBuildings?: InventoryLocationBuilding[];
  locationId: string | number;
  meterTypes?: InventoryMeterType[];
  tenants?: string[];
}

export interface LocationMetersData {
  inventoryMeters: { data: MeterFieldsData[] };
  inventoryLocations: {
    data: { name: string }[];
  };
}
