import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getApiKey } from '../utils/getApiKey';
import { getApiUrl } from '../utils/getApiUrl';

export interface AuthContext {
  apiKey?: string;
}

const httpLink = createHttpLink({
  uri: getApiUrl('graphql').toString(),
});

const authLink = setContext((_, { headers, apiKey: explicitApiKey }: { headers: Headers } & AuthContext) => {
  const apiKey = explicitApiKey ?? getApiKey();

  if (!apiKey) {
    return { headers };
  }

  return {
    headers: {
      ...headers,
      authorization: `Basic ${Buffer.from(`InventoryLocation:${apiKey}`).toString('base64')}`,
    },
  };
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
