/* eslint-disable no-undef */
import React, { useRef, useState, useEffect, FC, Children, isValidElement, cloneElement, CSSProperties } from 'react';

interface MapProps extends google.maps.MapOptions {
  style?: CSSProperties;
}

export const Map: FC<MapProps> = ({ children, style, center }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, { zoom: 14 }));
    }
  }, [map, ref]);

  useEffect(() => {
    if (!map) return;
    map.setOptions({
      ...(center?.lat && center?.lng ? { center } : {}),
      fullscreenControl: false,
      streetViewControl: false,
      tilt: 0,
    });
  }, [map, center]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, child => (isValidElement(child) ? cloneElement(child, { map }) : null))}
    </>
  );
};
