import styled from 'styled-components';

/**
 * Safely possitions content to the center of body
 */
export const StyledContenWrapper = styled.div`
  max-width: 100%;
  align-self: safe center;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  padding: 15px;
`;
